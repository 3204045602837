import Img from "../../../../Component/Elements/Media/components/Img";
import CSS from "./static/css/DocsThumbnailItem.module.css";

const DocsThumbnailItem = (
    {
        docs_img_src,
        onClick,
    }:{
        docs_img_src: string;
        onClick: React.MouseEventHandler<HTMLDivElement>;
    }
) => {
    return(
        <div 
            className={`g_click_event_item ${CSS.l_docs_thumbnail_item_main}`}
            onClick={onClick}
        >
            <Img
                class_name_list={[CSS.l_docs_img]}
                src={docs_img_src}
                object_fit={"contain"}
            />
        </div>
    )
}

export default DocsThumbnailItem;