import { useEffect, useState } from "react";

type cardLevelType = "level0" | "level1" | "level2";

const CardContainer = (
    {
        children,
        class_name_list,
        level,
        onClick,
    }:{
        children?: React.ReactNode;
        class_name_list?: string[];
        level?: cardLevelType;
        onClick?: React.MouseEventHandler<HTMLDivElement>
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>("");

    useEffect(() => {
        const total_class_name_list: string[] = ["g_card"];

        if(onClick){
            total_class_name_list.push("g_click_event_item");
        }

        if(level){
            total_class_name_list.push(level);
        }

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }

        setTotalClassName(total_class_name_list.join(" "));        
    }, [class_name_list, level, onClick])

    return(
        <div 
            className={totalClassName}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default CardContainer;