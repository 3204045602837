import CSS from "../static/css/OptionItemBlock.module.css"
import select_check_mark from "../../../Common/image/icon/select check mark@2x.png";
import Text from "../../../Component/Elements/Text/Text";
import Img from "../../../Component/Elements/Media/components/Img";
import Button from "../../../Component/Elements/Button/Button";
import IconButtonCheckCheckMark from "../../../Component/Elements/Icon/IconButtonCheck/IconButtonCheckCheckMark";

const OptionItemBlock = (
    {
        img_src,
        is_checked,
        option_name,
        selectOptionHandler,
        isReadOnly
    }:{
        img_src: string;
        is_checked: boolean;
        option_name: string;
        selectOptionHandler?: React.MouseEventHandler<HTMLButtonElement>;
        isReadOnly?: boolean;
    }
) => {
    return (
        <div className={CSS.l_option_item_block_main}>
            <div 
                className={CSS.l_option_item_block__img_container}
            >
                <Img 
                    class_name_list={[CSS.l_option_item_block__img_container__img]}
                    src={img_src}
                    object_fit={"cover"}
                />
            </div>
            <Text
                size={"size1"}
            >
                {option_name}
            </Text>

            {
                !(!!isReadOnly)
                &&  <div>
                        <Button
                            size={"auto"}
                            onClick={selectOptionHandler}
                            purpose={is_checked ? "cancel" : "save"}
                        >
                            {
                                is_checked
                                ? "선택 취소"
                                : "옵션 선택"
                            }
                        </Button>
                    </div>
            }

            {
                is_checked
                &&  <div className={CSS.l_option_item_block__select_icon}>
                        <IconButtonCheckCheckMark 
                            src={select_check_mark}
                            size={"small"}
                        />
                    </div>
            }
        </div>
    )
}

export default OptionItemBlock;