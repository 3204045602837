import { useEffect, useState } from "react";
import MediaCard from "../../../Component/Elements/MediaCard/MediaCard";
import CSS from "../static/css/productBlock.module.css";
import { scheduleProductDetailInterface, scheduleProductInterface } from "../static/interface/photoSelect.interface";
import Text from "../../../Component/Elements/Text/Text";
import Img from "../../../Component/Elements/Media/components/Img";

const ProductBlock = (
    {
        selectedProduct,
        onClick,
        is_highlight,
        is_hover_action_activate,
        is_print_badge,
    }:{
        selectedProduct: scheduleProductInterface;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        is_highlight: boolean;
        is_hover_action_activate: boolean;
        is_print_badge?: boolean;
    }
) => {

    const [totalClassNameList, setTotalClassNameList] = useState<string[]>([]);
    const [detailData, setDetailData] = useState<scheduleProductDetailInterface | null>(null);

    useEffect(() => {
        if(selectedProduct.detail && selectedProduct.detail.length > 0){
            setDetailData(selectedProduct.detail[0]);
        }else{
            setDetailData(null);
        }
    }, [selectedProduct])

    useEffect(() => {
        const total_class_name_list: string[] = ["g_grid_item"];

        if(onClick){
            total_class_name_list.push("g_click_event_item");
        }

        setTotalClassNameList(total_class_name_list);
    }, [onClick])

    return (
        <MediaCard
            class_name_list={totalClassNameList}
            onClick={onClick}
            src={selectedProduct.img_url.middle_url}
            size={"middle"}
            is_highlight={is_highlight}
            is_hover_action_activate={is_hover_action_activate}
            is_print_badge={is_print_badge}
        >
            <div className={CSS.l_product_block__info_continer}>
                <Text 
                    size={"size5"}
                    bold={true}
                    class_name_list={[CSS.l_product_block__info_continer__title]}
                >
                    {selectedProduct.product_name}
                </Text>
                {
                    detailData
                    &&  <Text
                            size={"size1"}
                            class_name_list={[CSS.l_product_block__info_continer__goout_text]}
                        >
                            {selectedProduct.goout === "Y" ? `배송시작(${selectedProduct.goout_date})` : "미출고"}
                        </Text>
                }
            </div>
        </MediaCard>
    )
}

export default ProductBlock