import { MouseEventHandler } from "react";
import Modal from "../../../Component/Elements/Modal/Modal";
import TextEditerQuill from "../../../Component/Elements/TextEditer/TextEditerQuill";
import CSS from "../static/css/ThemaDetailPageModal.module.css";
import TitleContainer from "./TitleContainer";

const ThemaDetailPageModal = (
    {
        isModalOpen,
        modalCloseHandler,
        children,
        title,
        photo_name,
        office_name,
        content,
        office_id,
        thema_id,
        type,
    }:{
        isModalOpen: boolean;
        modalCloseHandler: React.MouseEventHandler<HTMLDivElement>;
        children?: React.ReactNode;
        title: string;
        photo_name: string;
        office_name: string;
        content: string;
        office_id: number;
        thema_id: number;
        type: number;
    }
) => {
    const handleContainerClick: MouseEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation(); // 이벤트 전파를 중지시켜서 모달이 닫히는 것을 방지
    };

    return(
        <Modal
            isModalOpen={isModalOpen}
            modalCloseHandler={modalCloseHandler}
            isBackgroundClose={true}
        >
            <div 
                className={CSS.l_thema_detail_page_modal_main}
                onClick={handleContainerClick}
            >
                <div className={CSS.l_thema_detail_page_modal__title_container}>
                    <TitleContainer 
                        photo_name={photo_name}
                        title={title}
                        branch_name={office_name}
                    />
                </div>
                <div className={CSS.l_thema_detail_page_modal__text_editer_container}>
                    <TextEditerQuill 
                        read_only={true}
                        content={content}
                        classNameList={[CSS.l_thema_detail_page_modal__text_editer]}
                        office_id={office_id}
                        thema_id={thema_id}
                        type={type}
                    />
                </div>
                {children}
            </div>
        </Modal>
    )
}

export default ThemaDetailPageModal;