import Badge from "../../../Component/Elements/Badge/Badge";
import Button from "../../../Component/Elements/Button/Button";
import useImageZipDownloadREST from "../hooks/useImageZipDownloadREST.hook";

const ImageZipDownload = (
    {
        download_type,
        file_name,
        s3_url,
        is_print_badge,
        main_type,
        photo_upload_id,
    }:{
        download_type: number;
        file_name: string;
        s3_url: string;
        is_print_badge: boolean;
        main_type: number;
        photo_upload_id: number;
    }
) => {

    const isKakaoBrowser = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        return userAgent.includes('kakaotalk');
    };

    const {
        downloadHandler,
        imageZipDataIsLoading
    } = useImageZipDownloadREST({
        s3_url,
        download_type,
        main_type,
        photo_upload_id,
    })

    const handleDownload = () => {
        if(isKakaoBrowser()){
            alert("카카오톡 환경에서는 다운로드 진행이 어렵습니다.\n다른 브라우저(ex. 구글 크롬)이나 PC환경에서 진행해주세요.");            
            return ;
        }

        if(!window.confirm("사진들을 다운로드 하시겠습니까?")){
            return ;
        }

        downloadHandler(file_name);
    }

    if(imageZipDataIsLoading){
        return (
            <Button
                purpose={"disabled"}
                size={"size_full"}
            >
                다운로드 진행중입니다. 잠시만 기다려주세요.
            </Button>
        )
    }else{
        return(
            <Button
                onClick={handleDownload}
                disabled={imageZipDataIsLoading}
                size={"size_full"}
                purpose={"dev"}
            >
                <Badge
                    is_print_badge={is_print_badge}
                >
                    다운로드
                </Badge>
            </Button>
        )
    }

}

export default ImageZipDownload;