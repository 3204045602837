import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { callAxios, customToast, dev_console, isSuccess, isUseTestData, printStateMsg, whoAmI } from "../../../Common/ts/commonTools";
import { themaBookDetailListInterface } from "../../../PublicPage/ThemeBook/static/interface/themeBook.interface";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { scheduleProductInterface } from "../static/interface/photoSelect.interface";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";

const usePhotoProductOptionInfoDataListREST = (
    {
        selectedProduct
    }:{
        selectedProduct: scheduleProductInterface | null;
    }
) => { 
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            if(selectedProduct){
                result = true;
            }
        }

        return result;
    }

    const thema_book_detail_list_url = `api/v3/customers/thema_book_detail_list?type=1&office_id=${selectedProduct?.office_id}&photo_id=${selectedProduct?.detail_product_id}&thema_id=`;

    const {
        data: photoProductOptionInfoDataList = [],
        error: photoProductOptionInfoDataListError,
        isLoading: photoProductOptionInfoDataListIsLoading,
        refetch: photoProductOptionInfoDataListRefetch,
    } = useQuery({
        queryKey: ["usePhotoProductOptionInfoDataListREST", selectedProduct?.office_id, selectedProduct?.detail_product_id],
        queryFn: callPhotoProductOptionDataList,
        enabled: isAbleToRequest,
        staleTime: STALE_TIME,
        gcTime: GC_TIME,
        refetchOnMount: false,
        select: (response) => {
            dev_console.log(thema_book_detail_list_url);
            dev_console.log(response);
            if(!!response && isSuccess(response)){
                const row_response: defaultApiReaponseInterface<themaBookDetailListInterface[]> = response.data;
                return row_response.result;
            }else{
                printStateMsg(response);
                return [];
            }
        }
    })

    async function callPhotoProductOptionDataList(){
        const response = callAxios.api({
            url: thema_book_detail_list_url,
            method: "get",
        })

        return response;
    }
    
    useEffect(() => {
        if(!!photoProductOptionInfoDataListError){
            dev_console.error(photoProductOptionInfoDataListError);
        }
    }, [photoProductOptionInfoDataListError])

    return({
        photoProductOptionInfoDataList,
        photoProductOptionInfoDataListError,
        photoProductOptionInfoDataListIsLoading,
        photoProductOptionInfoDataListRefetch,
    })
}

export default usePhotoProductOptionInfoDataListREST;