import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console, isSuccess, whoAmI } from "../../../Common/ts/commonTools";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { contractDetailDataInterace } from "../interface/ContractDocs.interface";
import { useEffect } from "react";

const useContractDetailREST = (contract_id: number) => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer() && contract_id > 0){
            result = true;
        }

        return result;
    }

    const {
        data: contractDetailData = [],
        error: contractDetailDataError,
        isLoading: contractDetailDataIsLoading,
    } = useQuery({
        queryKey: ["contract_detail_data", contract_id],
        queryFn: callContractDetailData,
        select: (response) => {
            if(isSuccess(response)){
                const data: defaultApiReaponseInterface<contractDetailDataInterace[]> = response.data;
                return data.result;
            }else{
                return [];
            }
        },
        refetchOnMount: false,
        enabled: isAbleToRequest,
    })

    async function callContractDetailData() {
        const contract_detail_url = `api/v3/customers/contract/detail?contract_id=${contract_id}`;
        const result = await callAxios.api({
            url: contract_detail_url,
            method: "get",
        })

        dev_console.log(contract_detail_url);
        dev_console.log(result);
        
        return result;
    }

    useEffect(() => {
        if(contractDetailDataError){
            customToast.error("계약서 정보를 불러오는 과정에서 문제가 발생했습니다.");
        }
    }, [contractDetailDataError])

    return({
        contractDetailData,
        contractDetailDataError,
        contractDetailDataIsLoading,
    })
}

export default useContractDetailREST;