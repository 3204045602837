import { useEffect, useState } from "react";
import Button from "../../../../Component/Elements/Button/Button";
import Modal from "../../../../Component/Elements/Modal/Modal";
import { callAxios, customToast, dev_console } from "../../../../Common/ts/commonTools";
import { customerConfirmedType, managerConfirmedType } from "../../static/interface/PhotoList.interface";
import { EXAMPLE_TEXT_LIST } from "../../../../Common/ts/const";
import TextFlexWrap from "../../../../Component/Elements/TextFlexWrap/TextFlexWrap";
import Textarea from "../../../../Component/Elements/Textarea/Textarea";
import { isCanConfrom } from "../../static/ts/confirmEvent.tools";
import usePhotoListDataREST from "../../hooks/usePhotoListDataREST.hook";
import CSS from "./static/css/CustomerConfirm.module.css";
import usePhotoDetailDataREST from "../../hooks/usePhotoDetailDataREST.hook";

const CustomerConfirm = (
    {
        photo_upload_id,
        photo_type,
        customer_confirmed,
        manager_confirmed,
        confirm_reason,
        request_num,
        contract_product_id,
    }:{
        photo_upload_id: number;
        photo_type: string;
        customer_confirmed: customerConfirmedType;
        manager_confirmed: managerConfirmedType;
        confirm_reason: string | null;
        request_num: string | null;
        contract_product_id: string | null;
    }
) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRefusal, setIsRefusal] = useState<boolean>(false);
    const [refusalMsg, setRefusalMsg] = useState<string>("");
    const [btnState, setBtnState] = useState<number>(0);

    const {
        photoDetailDataRefetch,
    } = usePhotoDetailDataREST({
        photo_upload_id,
        main_type: 1,
    })

    const {
        photoListDataRefetch,
    } = usePhotoListDataREST({
        main_type: 1,
        status: 2,
    })

    useEffect(() => {
        setRefusalMsg(confirm_reason || "");
    }, [confirm_reason])

    useEffect(() => {
        let state: number = 0;
        if(photo_type){
            const photo_type_num:number = parseInt(photo_type);

            if(!isNaN(photo_type_num)){
                if(photo_type_num === 2){
                    
                    const result = isCanConfrom({
                        customer_confirmed: customer_confirmed,
                        manager_confirmed: manager_confirmed,
                    })

                    
                    if(result.flag){
                        state = 1;
                    }else{
                        if(result.order === "C"){
                            if(result.result === "N"){
                                state = 4;
                            }else if(result.result === "Y"){
                                state = 2;
                            }else if(result.result === "A"){
                                state = 3;
                            }
                        }else if(result.order === "M"){
                            state = 3;
                        }
                    }
                }
            }
        }
        setBtnState(state);
    }, [photo_type, customer_confirmed, refusalMsg, isRefusal, request_num])

    const refusalModalOpenBtnHandler = () => {

        const request_num_number: number = parseInt(request_num || "");
        if(isNaN(request_num_number)){
            customToast.error("재요청 데이터에서 문제가 발견되었습니다.\n스튜디오로 문의해주세요.");
            return ;
        }

        if(request_num_number > 2){
            customToast.error("재요청 가능 횟수를 초과하셨습니다.\n스튜디오로 문의해주세요.");
            return ;
        }

        setIsRefusal(true);
    }

    const confirmBtnHandler = () => {
        if(isLoading){
            return ;
        }

        if(!window.confirm("상품 제작을 시작할까요?\n제작이 시작되면 변경이 불가능합니다.")){
            return ;
        }

        requestEditPhotoConf({confirm: "accept"})
        .then((response) => {
            dev_console.log(response);

            if(response.status === 200){
                photoDetailDataRefetch();
                photoListDataRefetch();
                customToast.success("제품제작이 승인되었습니다.");
            }else{
                customToast.error("제품제작승인요청에 실패했습니다.");
            }
        })
        .catch((error) => {
            dev_console.error(error);
            customToast.error("제품제작승인요청 과정에서 문제가 발생했습니다.");
        })
        .finally(() => {
            setIsLoading(false);
        })
    }

    const refusalBtnHandler = () => {
        if(isLoading){
            return ;
        }

        const request_num_number: number = parseInt(request_num || "");
        if(isNaN(request_num_number)){
            customToast.error("재요청 데이터에서 문제가 발견되었습니다.\n스튜디오로 문의해주세요.");
            return ;
        }

        if(request_num_number > 2){
            customToast.error("재요청 가능 횟수를 초과하셨습니다.\n스튜디오로 문의해주세요.");
            return ;
        }

        if(refusalMsg.length === 0){
            customToast.info("사진이 마음에 들지 않는 이유를 작성해주세요.");
            return ;
        }

        if(window.confirm("재편집이 요청됩니다.\n진행하시겠습니까?")){
            
            requestEditPhotoConf({confirm: "reject"})
            .then((response) => {
                dev_console.log(response);
    
                if(response.status === 200){
                    photoDetailDataRefetch();
                    photoListDataRefetch();
                    closeModalHander();
                    customToast.success("재편집 요청이 신청되었습니다.");
                }else{
                    customToast.error("제품제작승인요청에 실패했습니다.");
                }
            })
            .catch((error) => {
                dev_console.error(error);
                customToast.error("제품제작승인요청 과정에서 문제가 발생했습니다.");
            })
            .finally(() => {
                setIsLoading(false);
            })
        }
    }

    async function requestEditPhotoConf(
        {
            confirm
        }:{
            confirm: "accept" | "reject"
        }
    ){
        const config = {
            photo_upload_id: `${photo_upload_id}`,
            confirm: confirm === "accept" ? 2 : 4,
            confirm_reason: confirm === "reject" ? refusalMsg : "",
            contract_product_id: contract_product_id,
        }

        setIsLoading(true);
        const edit_photo_conf_url = "api/v3/customers/edit-photo-conf";
        const response = await callAxios.api({
            url: edit_photo_conf_url,
            method: "post",
            dataType: "json",
            data: config,
        })

        return response;
    }

    const refusalMsgOnChageHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRefusalMsg(event.target.value);
    }

    const closeModalHander = () => {
        setIsRefusal(false);
    }

    switch(btnState){
        case 1:
            return(
                <div className={CSS.l_customer_confirm_main}>    
                    <div className={CSS.l_customer_confirm__btn_container}>
                        <Button
                            purpose={"delete"}
                            size={"size_full"}
                            onClick={refusalModalOpenBtnHandler}
                            disabled={isLoading}
                        >
                            사진이 마음에 들지 않아요.
                        </Button>
                        <Button
                            purpose={"save"}
                            size={"size_full"}
                            onClick={confirmBtnHandler}
                            disabled={isLoading}
                        >
                            사진이 마음에 들어요!
                        </Button>
                    </div>
                    
                    <Modal
                        isModalOpen={isRefusal}
                        modalCloseHandler={closeModalHander}
                    >
                        <div className={`${CSS.l_customer_confirm__refusal_textarea_container}`}>
                            <Textarea
                                class_name_list={[CSS.l_customer_confirm__refusal_textarea_container__textarea]}
                                onChange={refusalMsgOnChageHandler}
                                placeholder={EXAMPLE_TEXT_LIST.join(`\n`)}
                                disabled={isLoading}
                            >
                                {refusalMsg}
                            </Textarea>
                            <div className={CSS.l_customer_confirm__refusal_textarea_container__btn_container}>
                                <Button
                                    onClick={refusalBtnHandler}
                                    size={"size1"}
                                    purpose={"delete"}
                                    disabled={isLoading}
                                >
                                    재편집요청
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            );
        case 2:
            return(
                <div className={CSS.l_customer_confirm_main}>
                    <div className={CSS.l_customer_confirm__btn_container}>
                        <Button
                            size={"size_full"}
                            purpose={"disabled"}
                        >
                            최종적인 확인이 완료되었습니다.
                        </Button>
                    </div>
                </div>
            );
        case 3:
            return(
                <div className={CSS.l_customer_confirm_main}>
                    <div className={CSS.l_customer_confirm__btn_container}>
                        <Button
                            size={"size_full"}
                            purpose={"disabled"}
                        >
                            담당자에 의하여 재편집이 요청되었습니다.
                        </Button>
                    </div>
                </div>
            );
        case 4:
            return(
                <div className={CSS.l_customer_confirm_main}>
                    <TextFlexWrap
                        classNameList={["g_text_wrap", "warning", "g_text", "size1"]}
                        text_list={[`재편집이 요청되었습니다.` ]}
                        text_list_justify_content={"center"}
                    />
                    <div className={CSS.l_customer_confirm__btn_container}>
                        <Button
                            size={"size_full"}
                            onClick={refusalModalOpenBtnHandler}
                        >
                            재편집 요청 내용 확인
                        </Button>
                    </div>

                    <Modal
                        isModalOpen={isRefusal}
                        modalCloseHandler={closeModalHander}
                    >
                        <div className={`${CSS.l_customer_confirm__refusal_textarea_container}`}>
                            <Textarea
                                class_name_list={[CSS.l_customer_confirm__refusal_textarea_container__textarea]}
                                readOnly={true}
                            >
                                {refusalMsg}
                            </Textarea>
                        </div>
                    </Modal>
                </div>
            );
        default:
            return(<></>);
    }
}

export default CustomerConfirm;