import { useEffect } from "react";
import TextFlexWrap from "../../../Component/Elements/TextFlexWrap/TextFlexWrap";
import ImageZipDownload from "./ImageZipDownload";
import useUploadDate from "../hooks/useUploadDate.hook";
import useDateString from "../../../Common/hooks/useDateString.hook";
import useExpirationDate from "../hooks/useExpirationDate.hook";

const DownloadBtnContainer = (
    {
        download_type,
        s3_url,
        file_name,
        upload_date,
        is_print_badge,
        main_type,
        photo_upload_id,
    }:{
        download_type: number;
        s3_url: string;
        file_name: string;
        upload_date: string;
        is_print_badge: boolean;
        main_type: number;
        photo_upload_id: number;
    }
) => {
    const { downloadExpirationDate } = useUploadDate(upload_date);
    const { dateString: downloadExpirationDateString, setOriginDateData} = useDateString(downloadExpirationDate);
    const { isExpirationDate, setDateData } = useExpirationDate(downloadExpirationDate);

    useEffect(() => {
        setOriginDateData(downloadExpirationDate);
        setDateData(downloadExpirationDate);
    }, [downloadExpirationDate])
    
    return (
        <>
            {
                downloadExpirationDateString
                &&  <TextFlexWrap
                        classNameList={["g_text_wrap", "warning", "g_text", "size1"]}
                        text_list={[`${downloadExpirationDateString}까지 다운받을 수 있습니다.` ]}
                        text_list_justify_content={"center"}
                    />
            }
            {
                !isExpirationDate
                &&  <ImageZipDownload
                        download_type={download_type}
                        s3_url={s3_url}
                        file_name={file_name}
                        is_print_badge={is_print_badge}
                        main_type={main_type}
                        photo_upload_id={photo_upload_id}
                    />
            }
        </>
    )
}

export default DownloadBtnContainer;