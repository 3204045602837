import CSS from "../../static/css/themeItemBlock.module.css"
import IconButtonCheckCheckMark from "../../../../Component/Elements/Icon/IconButtonCheck/IconButtonCheckCheckMark";
import select_check_mark from "../../../../Common/image/icon/select check mark@2x.png";
import Img from "../../../../Component/Elements/Media/components/Img";
import Button from "../../../../Component/Elements/Button/Button";
import Text from "../../../../Component/Elements/Text/Text";
import { useEffect, useState } from "react";

const ThemeItemBlock = (
    {
        img_src,
        is_checked,
        theme_name,
        viewDetailHandler,
        selectThemeHandler,
        isReadOnly
    }:{
        img_src: string;
        is_checked: boolean;
        theme_name: string;
        viewDetailHandler?: React.MouseEventHandler<HTMLDivElement>;
        selectThemeHandler?: React.MouseEventHandler<HTMLButtonElement>;
        isReadOnly?: boolean;
    }
) => {

    const [imgContainerClassName, setImgContainerClassName] = useState<string>("");

    useEffect(() => {
        const img_container_class_name_list: string[] = [];

        if(!!viewDetailHandler){            
            img_container_class_name_list.push("g_click_event_item");
        }

        img_container_class_name_list.push(CSS.l_theme_item_block__img_container);

        setImgContainerClassName(img_container_class_name_list.join(" "));
    }, [viewDetailHandler])

    return (
        <div className={CSS.l_theme_item_block_main}>
            <div 
                className={imgContainerClassName}
                onClick={viewDetailHandler}
            >
                <Img 
                    class_name_list={[CSS.l_theme_item_block__img_container__img]}
                    src={img_src}
                    object_fit={"cover"}
                />
            </div>
            <Text
                size={"size1"}
            >
                {theme_name}
            </Text>

            {
                !(!!isReadOnly)
                &&  <div>
                        <Button
                            size={"auto"}
                            onClick={selectThemeHandler}
                            purpose={is_checked ? "cancel" : "save"}
                        >
                            {
                                is_checked
                                ? "선택 취소"
                                : "테마 선택"
                            }
                        </Button>
                    </div>
            }

            {
                is_checked
                &&  <div className={CSS.l_theme_item_block__select_icon}>
                        <IconButtonCheckCheckMark 
                            src={select_check_mark}
                            size={"small"}
                        />
                    </div>
            }
        </div>
    )
}

export default ThemeItemBlock;