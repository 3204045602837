import { useNavigate } from "react-router-dom";
import Button from "../../../../../../Component/Elements/Button/Button";
import CardContainer from "../../../../../../Component/Elements/CardContainer/CardContainer";
import Img from "../../../../../../Component/Elements/Media/components/Img";
import Text from "../../../../../../Component/Elements/Text/Text";
import { photoReadDataInterface } from "../../../../../../CustomerPage/PhotoList/static/interface/PhotoList.interface";
import CSS from "../static/css/ApprovalItemCard.module.css"

const ApprovalItemCard = (
    {
        photoData
    }:{
        photoData: photoReadDataInterface
    }
) => {
    const navigate = useNavigate();

    const moveDetailPage = () => {
        navigate(
            "/photoDetail"
            ,{
                state:{
                    selected_photo_upload_id: `${photoData.photo_upload_id}`,
                    main_type: `1`,
                    viewRange_type: `2`,
                    photo_type: photoData.photo_type,
                }
            }
        );
    }

    return(
        <CardContainer
            class_name_list={[CSS.l_approval_item_card_main]}
            onClick={moveDetailPage}
        >
            <div className={CSS.l_approval_item_card__img_container}>
                <Img
                    src={photoData.thumbnail?.middle_url}
                    object_fit={"cover"}
                />
            </div>
            <div className={CSS.l_approval_item_card__content_container}>
                <div
                    className={CSS.l_approval_item_card__content_container__text_container}
                >
                    <Text
                        size={"size4"}
                    >
                        {photoData.photo_shoot_name}
                    </Text>
                </div>
                <Button
                    purpose={"delete"}
                    size={"size_full"}
                    onClick={moveDetailPage}
                >
                    편집완료된 사진 확인하기
                </Button>
            </div>
        </CardContainer>
    )
}

export default ApprovalItemCard;