import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console, isSuccess, printStateMsg, setQueryParameter, whoAmI } from "../../../Common/ts/commonTools";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { themaBookDetailListInterface } from "../static/interface/themeBook.interface";
import { useEffect, useRef } from "react";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";

const useThemeDetailDataREST = (
    {
        type,
        office_id,
        photo_id,
        thema_id,
    }:{
        type: string;
        office_id: string;
        photo_id: string;
        thema_id: string;
    }
) => {

    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer() || whoAmI.isAdmin()){
            if(!!type && !!office_id && !!thema_id){
                result = true;
            }
        }

        return result;
    }

    const {
        data: themeDetailData = null,
        error: themeDetailDataError,
        isLoading: themeDetailDataIsLoading,
        refetch: themeDetailDataRefetch,
    } = useQuery({
        queryKey: ["themeDetailData", type, office_id, photo_id, thema_id],
        queryFn: callThemeDetailData,
        select: (response) => {
            if(response){
                if(isSuccess(response)){
                    const row_response: defaultApiReaponseInterface<themaBookDetailListInterface[]> = response.data;
                    if(row_response.result && row_response.result.length > 0){
                        return row_response.result[0]
                    }else{
                        return null;
                    }
                }else{
                    printStateMsg(response);
                    return null;
                }
            }else{
                return null;
            }
        },
        enabled: isAbleToRequest(),
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
        refetchOnMount: false,  // 다시 마운트 되더라도 새로운 요청을 보내지 않게 처리
    })

    async function callThemeDetailData(){
        const thema_book_detail_list_endpoint:string = "api/v3/customers/thema_book_detail_list";
        const query_parameter_string = setQueryParameter([
            {
                key: "type",
                value: type,
            },{
                key: "office_id",
                value: office_id,
            },{
                key: "photo_id",
                value: photo_id,
            },{
                key: "thema_id",
                value: thema_id,
            }
        ])

        const thema_book_detail_list_url = thema_book_detail_list_endpoint.concat("?", query_parameter_string);
        
        const response = await callAxios.api({
            url: thema_book_detail_list_url,
            method: "get",
        })

        dev_console.log(thema_book_detail_list_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        if(!!themeDetailDataError){
            dev_console.error(themeDetailDataError);
            customToast.error("테마 상세정보를 요청하는 과정에서 문제가 발생했습니다.");
        }
    }, [themeDetailDataError])

    return({
        themeDetailData,
        themeDetailDataIsLoading,
        themeDetailDataRefetch,
    })
}

export default useThemeDetailDataREST;