import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console } from "../../../Common/ts/commonTools";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import usePhotoDetailDataREST from "./usePhotoDetailDataREST.hook";
import usePhotoListDataREST from "./usePhotoListDataREST.hook";

const useImageZipDownloadREST = (
    {
        s3_url,
        download_type,
        photo_upload_id,
        main_type,
    }:{
        s3_url: string;
        download_type: number;
        photo_upload_id: number,
        main_type: number,
    }
) => {
    const {
        photoDetailDataRefetch
    } = usePhotoDetailDataREST({
        photo_upload_id,
        main_type,
    })

    const {
        photoListDataRefetch: originPhotoListDataRefetch
    } = usePhotoListDataREST({
        main_type: main_type as 1 | 2,
        status: 1,
    })

    const {
        photoListDataRefetch: editingPhotoListDataRefetch
    } = usePhotoListDataREST({
        main_type: main_type as 1 | 2,
        status: 2,
    })

    const {
        photoListDataRefetch: editedPhotoListDataRefetch
    } = usePhotoListDataREST({
        main_type: main_type as 1 | 2,
        status: 3,
    })

    async function recallData(){
        await photoDetailDataRefetch();

        await originPhotoListDataRefetch();

        if(main_type === 1){
            await editingPhotoListDataRefetch();
            await editedPhotoListDataRefetch();
        }
    }

    const {
        data: imageZipData,
        error: imageZipDataError,
        isLoading: imageZipDataIsLoading,
        refetch: imageZipDataRefetch,
    } = useQuery({
        queryKey: ["imageZipData", s3_url, download_type],
        queryFn: handleDownload,
        refetchOnWindowFocus: false, // 사용자 포커스 변경 시 자동 재요청 방지
        enabled: false, // 오직 refetch로만 동작하도록
        refetchOnMount: false,  // 다시 마운트 되더라도 새로운 요청을 보내지 않게 처리
    })

    async function handleDownload(){
        const config = {
            s3_url: s3_url,
            download_type: download_type,/* 1: 원본, 편집본 등 파스텔에서 올린 img, 2: 사용자 select 파일, 3: 사용자 후기 파일*/
        }

        const photo_download_url = "api/v3/customers/photo-download";
        const response = await callAxios.api({
            url: photo_download_url,
            method: "post",
            dataType: "json",
            data: config,
            responseType: "blob",
            timeOut: 180000,
        })

        dev_console.log(photo_download_url);
        dev_console.log(response);

        return response;
    }

    // 해당파일이 blob인지 검증
    function isBlob(data: any): data is Blob {
        return data instanceof Blob;
    }

    const downloadHandler = (file_name: string) => {
        if(imageZipData && imageZipData.data && isBlob(imageZipData.data)){
            saveAs(imageZipData.data, file_name);
            recallData();
        }else{
            toast.promise(
                imageZipDataRefetch(),
                {
                    pending: '다운로드를 준비중입니다.',
                    success: '다운로드 완료!',
                    error: '사진 파일 다운로드에 실패했습니다.',
                }
            )
            .then((response) => {
                dev_console.log(response);
                if(response.isSuccess && response.data &&  isBlob(response.data.data)){
                    saveAs(response.data.data, file_name);
                    recallData();
                }else{
                    dev_console.error(response);
                    customToast.error("사진 파일 다운로드에 실패했습니다.");
                }
            })
            .catch((error) => {
                dev_console.error(error);
                if(error.name === "AbortError"){
                    customToast.error("다운로드가 취소되었습니다.");
                }else{
                    customToast.error("사진 파일 다운로드에 실패했습니다.");
                }
            })
        }
    }

    useEffect(() => {
        if(!!imageZipDataError){
            dev_console.error(imageZipDataError);
            customToast.error("사진 파일 다운로드에 실패했습니다.");
        }
    }, [imageZipDataError])

    return({
        downloadHandler,
        imageZipDataIsLoading
    })
}

export default useImageZipDownloadREST;