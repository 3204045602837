import { createContext, useEffect, useRef, useState } from "react";
import TitleBtnWrap from "../../Component/Elements/TitleBtnWrap/TitleBtnWrap";
import ProductBlock from "./components/ProductBlock";
import { customToast, dev_console, isAbleAccesser } from "../../Common/ts/commonTools";
import WideCard from "../../Component/Elements/WideCard/WideCard";
import UploadInputBtnContainer from "./components/UploadInputBtnContainer";
import { scheduleProductInterface } from "./static/interface/photoSelect.interface";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import CSS from "./static/css/photoSelect.module.css";
import { isNeedToSeletItem } from "./static/ts/photoSelectTools";
import { themaBookDetailListInterface } from "../../PublicPage/ThemeBook/static/interface/themeBook.interface";
import { useLocation } from "react-router-dom";
import Flex from "../../Component/Elements/Flex/Flex";
import ProductBlockDetail from "./components/ProductBlockDetail";
import usePhotoProductDataREST from "./hooks/usePhotoProductDataREST.hook";
import LoopAnimation from "../../Component/Elements/LoopAnimation/LoopAnimation";
import usePhotoProductBadge from "./hooks/usePhotoProductBadge.hook";
import usePhotoProductOptionInfoDataListREST from "./hooks/usePhotoProductOptionInfoDataListREST.hook";

interface photoSelectContextInterface{
    isUploading: boolean;
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
    isOptionLoading: boolean;
    isOptionError: boolean;
    selectedProductOptionDataList: themaBookDetailListInterface[];
}

const photoSelectContextDefaultValue: photoSelectContextInterface = {
    isUploading: false,
    setIsUploading: () => {},
    isOptionLoading: false,
    isOptionError: false,
    selectedProductOptionDataList: [],
}

export const photoSelectContext = createContext<photoSelectContextInterface>(photoSelectContextDefaultValue);

const PhotoSelect = () => {

    // 사진을 업로드할 때 상태
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const [selectedProduct, setSelectedProduct] = useState<scheduleProductInterface | null>(null);

    const [searchType, setSearchType] = useState<0 | 1 | 2>(0);

    const [isUploadedImgModalOpen, setIsUploadedImgModalOpen] = useState<boolean>(false);

    // 상품 목록
    const {
        search_type,
        photoProductDataList,
        photoProductDataListIsLoading,
    } = usePhotoProductDataREST({
        search_type: searchType
    });

    // badge출력 정보
    const {
        photoProductBadgeList
    } = usePhotoProductBadge();

    // 선택한 제품의 옵션정보
    const {
        photoProductOptionInfoDataList,
        photoProductOptionInfoDataListError,
        photoProductOptionInfoDataListIsLoading,
        photoProductOptionInfoDataListRefetch,
    } = usePhotoProductOptionInfoDataListREST({
        selectedProduct
    })

    const uploadInputRef = useRef<HTMLInputElement>(null);

    const location = useLocation();

    const productOnClickHandler = (item: scheduleProductInterface) => {
        if(window.innerWidth <= 854){
            customToast.error("제품 제작 신청은 PC환경에서만 가능합니다.", "photo_product_select_is_disabled_at_mobile")
            return ;
        }
        setSelectedProduct(item);
    }

    useEffect(() => {
        isAbleAccesser(["C"]);
    
        const data = location.state as scheduleProductInterface;
        setSelectedProduct(data);
    }, [])

    return (
        <photoSelectContext.Provider 
            value={{
                isUploading,
                setIsUploading,
                isOptionLoading: photoProductOptionInfoDataListIsLoading,
                isOptionError: !!photoProductOptionInfoDataListError,
                selectedProductOptionDataList: photoProductOptionInfoDataList,
            }}
        >
            <div className={CSS.l_photo_select_main}>
                <div className="g_title">제품 제작 신청</div>
                
                <div className={CSS.l_contents}>
                    <div className={`g_card ${CSS.l_select_product_container}`}>
                        <TitleBtnWrap<0 | 1 | 2> 
                            valueState={search_type}
                            setValueState={setSearchType}
                            value_list={[0, 1, 2]}
                            innerText_list={["전체", "제품 제작 신청", "신청 완료"]}
                            is_activated={true}
                            is_print_badge_list={photoProductBadgeList}
                        />
                        {
                            photoProductDataListIsLoading
                            ?   <WideCard>
                                    <LoopAnimation />
                                </WideCard>
                            :   photoProductDataList.length > 0
                                ?   <Flex>
                                        {
                                            photoProductDataList.map((item, index) => {
                                                let is_print_badge = isNeedToSeletItem(item);

                                                return (
                                                    <ProductBlock 
                                                        key={index}

                                                        selectedProduct={item}
                                                        onClick={productOnClickHandler.bind(null, item)}

                                                        is_highlight={item.contract_product_id === selectedProduct?.contract_product_id}
                                                        is_hover_action_activate={true}
                                                        is_print_badge={is_print_badge}
                                                    />
                                                )
                                            })
                                        }
                                    </Flex>
                                :   <WideCard>
                                        제품 정보가 없습니다.
                                    </WideCard>
                        }
                    </div>
                    {
                        selectedProduct
                        &&  <div className={`g_display_none_object mobile flex ${CSS.l_product_info}`}>
                                <ProductBlockDetail
                                    selectedProduct={selectedProduct}

                                    is_highlight={false}
                                    is_hover_action_activate={false}
                                />                                
                                {
                                    (selectedProduct.detail && selectedProduct.detail.length > 0)
                                    &&   <UploadInputBtnContainer 
                                            selectedProduct={selectedProduct}
                                            uploadInputRef={uploadInputRef}
                                            isUploadedImgModalOpen={isUploadedImgModalOpen}
                                            setIsUploadedImgModalOpen={setIsUploadedImgModalOpen}
                                        />
                                }
                            </div>
                    }
                </div>
                <LoopAnimationModal
                    isModalOpen={isUploading}
                />       
            </div>
        </photoSelectContext.Provider>
    )
}

export default PhotoSelect;