import { useEffect, useState } from "react";
import { transforEventList } from "../../../../../Component/Elements/CustomFullCalendar/static/ts/fullCalendarTools";
import CustomFullCalendar from "../../../../../Component/Elements/CustomFullCalendar/CustomFullCalendar";
import { progressScheduleInterface } from "../../../../../CustomerPage/Reservation/static/interface/reservationHome.interface";
import { resetTime } from "../../../../../Common/ts/commonTools";
import WideCard from "../../../../../Component/Elements/WideCard/WideCard";
import ScheduleCard from "./components/ScheduleCard";
import useProgressScheduleDataAllScheduleType from "../../../../../CustomerPage/Reservation/hooks/useProgressScheduleDataAllScheduleType";

import Not_Found_illustration_2x from "../../static/img/Not Found illustration@2x.png";
import CSS from "../../static/css/scheduleCalendar.module.css";

const ScheduleCalendar = () => {

    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
    const [selectedDayEventList, setSelectedDayEventList] = useState<progressScheduleInterface[]>([]);

    const {progressScheduleDataList} = useProgressScheduleDataAllScheduleType({status_type: 0});

    useEffect(() => {
        setSelectedDate(new Date());
    }, [])

    useEffect(() => {
        if(selectedDate && progressScheduleDataList){

            const init_seleced_date = resetTime(selectedDate);

            const today_event_list: progressScheduleInterface[] = progressScheduleDataList.filter((filter_item) => {                
                const event_data = new Date(filter_item.schedule_date);
                const init_event_data = resetTime(event_data);

                if(init_event_data && init_seleced_date && init_event_data.getTime() === init_seleced_date.getTime()){
                    return true;
                }else{
                    return false;
                }
            });

            setSelectedDayEventList(today_event_list)
        }else{
            setSelectedDayEventList([]);
        }
    }, [selectedDate, progressScheduleDataList])

    return(
        <div className={`g_card ${CSS.l_schedule_calendar_main}`}>
            <div className={CSS.l_schedule_calendar__calendar_container}>
                <CustomFullCalendar 
                    selectedDate={selectedDate} 
                    setSelectedDate={setSelectedDate}
                    events={transforEventList(progressScheduleDataList)}
                />
            </div>
            <div className={CSS.l_schedule_calendar__events_container}>
                {
                    selectedDayEventList.length > 0
                    ?   selectedDayEventList.map((item, index) => {
                            return (
                                <ScheduleCard
                                    key={index}
                                    schedule={item}
                                />
                            )
                        })
                    :   <WideCard
                            background_color={"white"} 
                        >
                            <div className={CSS.l_schedule_calendar__events_container__no_img_container}>
                                <img
                                    className={CSS.l_schedule_calendar__events_container__no_img_container__img} 
                                    src={Not_Found_illustration_2x}
                                />
                                <div className="g_text size1">
                                    스케줄이 없습니다.
                                </div>
                            </div>
                        </WideCard>
                }
            </div>
        </div>
    )
}

export default ScheduleCalendar;