import { useContext, useEffect, useState } from "react";
import Modal from "../../../Component/Elements/Modal/Modal";
import Grid from "../../../Component/Elements/Grid/Grid";
import MediaCard from "../../../Component/Elements/MediaCard/MediaCard";
import { callAxios, customToast, dev_console, imageFilesUpload, isBetweenNum, isSuccess, isUseTestData, printStateMsg } from "../../../Common/ts/commonTools";
import TextFlexWrap from "../../../Component/Elements/TextFlexWrap/TextFlexWrap";
import { choicesAvailableNumInterface, previewUrlInterface, scheduleProductInterface } from "../static/interface/photoSelect.interface";
import { photoSelectContext } from "../PhotoSelect";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import Button from "../../../Component/Elements/Button/Button";
import CSS from "../static/css/uploadedImageContainerModal.common.module.css";
import AlbumProductInputContainer from "./AlbumProductInputContainer";
import RequestTextArea from "./RequestTextArea";
import AddressContainer from "./AddressContainer";
import { uploadInputBtnContainerContext } from "./UploadInputBtnContainer";
import OptionsContainer from "./OptionsContainer";
import InputTitle from "./InputTitle";
import DragAndDrop from "../../../Component/Elements/DragAndDrop/DragAndDrop";
import WideCard from "../../../Component/Elements/WideCard/WideCard";
import Tooltip from "../../../Component/Elements/Tooltip/Tooltip";
import Img from "../../../Component/Elements/Media/components/Img";
import { themaBookDetailListInterface } from "../../../PublicPage/ThemeBook/static/interface/themeBook.interface";

const UploadedImageContainerModal = (
    {
        selectedProduct,
        quantity_of_photos_to_choose,
        isModalOpen,
        setIsModalOpen,
        selectedFileListRef,
        previewUrls, 
        setPreviewUrls,
        uploadInputRef,
        isReRequest,
        is_album,
        onDropHandler,
        readonly,
    }:{
        selectedProduct: scheduleProductInterface,
        quantity_of_photos_to_choose: choicesAvailableNumInterface;
        isModalOpen: boolean;
        setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
        selectedFileListRef?: React.MutableRefObject<File[]>;
        previewUrls: previewUrlInterface[];
        setPreviewUrls: React.Dispatch<React.SetStateAction<previewUrlInterface[]>>;
        uploadInputRef: React.RefObject<HTMLInputElement>;
        isReRequest: boolean;
        is_album: boolean;
        onDropHandler?: (event: React.DragEvent<HTMLDivElement>) => void;
        readonly?: boolean;
    }
) => {
    const [selectedOptionList, setSelectedOptionList] = useState<themaBookDetailListInterface[]>([]);
    const [imgList, setImgList] = useState<previewUrlInterface[]>([]);
    const [node, setNode] = useState<React.ReactNode | null>(null);

    const [isMeetTheConditions, setIsMeetTheConditions] = useState<boolean>(false);

    const photo_select_context = useContext(photoSelectContext);
    const upload_input_btn_container_context = useContext(uploadInputBtnContainerContext);

    useEffect(() => {
        const selected_product_opiton_id_list = [selectedProduct.option_id];
        const result = photo_select_context.selectedProductOptionDataList.filter((item) => selected_product_opiton_id_list.includes(item.thema_id));
        
        if(!!result){
            setSelectedOptionList(result);
        }else{
            setSelectedOptionList([]);
        }
    }, [selectedProduct, photo_select_context.selectedProductOptionDataList])

    useEffect(() => {
        setIsMeetTheConditions(isBetweenNum({
            min: quantity_of_photos_to_choose.min,
            max: quantity_of_photos_to_choose.max,
            value: previewUrls.length,
            inclusive: true
        }))
    }, [quantity_of_photos_to_choose, previewUrls])

    useEffect(() => {
        if(readonly && selectedProduct.select_photo_list.length > 0){
            setImgList(selectedProduct.select_photo_list.map((item) => {
                return({
                    file_name: "",
                    file_url: item.img_url.middle_url,
                })
            }));
        }else if(!readonly && previewUrls.length > 0){
            setImgList(previewUrls)
        }else{
            setImgList([]);
        }
    }, [readonly, selectedProduct, previewUrls])

    useEffect(() => {
        let code: number = 0;

        if(readonly){
            if(imgList.length === 0){
                code = 1;
            }else{
                code = 2;
            }
        }else{
            if(imgList.length === 0){
                code = 3;
            }else{
                code = 4;
            }
        }

        switch(code){
            case 1:
                setNode(
                    <WideCard>
                        <div>
                            사진 정보가 없습니다.
                        </div>
                    </WideCard>
                )
                break;
            case 2:
                setNode(
                    <Grid class_name_list={["g_card", "inner", CSS.l_uploaded_image_wrap_modal__grid]}>
                        {
                            imgList.map((item, index) => {
                                return(
                                    <div 
                                        className={`g_grid_item ${CSS.l_uploaded_image_wrap_modal__grid__item}`}
                                        key={index}
                                    >
                                        <MediaCard
                                            src={item.file_url}
                                            alt={`Preview ${index}`}
                                            object_fit={"contain"}
                                            size={"full"}
                                        >
                                            <div className={`g_text size1 ${CSS.l_media_card_file_name_container}`}>
                                                {item.file_name}
                                            </div>
                                        </MediaCard>
                                    </div>
                                )
                            })
                        }
                    </Grid>
                )
                break;
            case 3:
                setNode(
                    <DragAndDrop
                        onDropHandler={onDropHandler}
                        innerText="사진 추가 버튼 혹은 드래그 앤 드롭으로 이미지파일을 업로드하세요.(jpg)"
                    />
                )
                break;
            case 4:
                setNode(
                    <DragAndDrop
                        onDropHandler={onDropHandler}
                    >
                        <Grid class_name_list={["g_card", "inner", CSS.l_uploaded_image_wrap_modal__grid]}>
                            {
                                imgList.map((item, index) => {
                                    return(
                                        <div 
                                            className={`g_grid_item ${CSS.l_uploaded_image_wrap_modal__grid__item}`}
                                            key={index}
                                        >
                                            <Tooltip
                                                tooltip_node={
                                                    <Img 
                                                        src={item.file_url}
                                                    />
                                                }
                                                position={"center"}
                                                max_width={"middle"}
                                            >
                                                <MediaCard
                                                    src={item.file_url}
                                                    alt={`Preview ${index}`}
                                                    object_fit={"contain"}
                                                    size={"full"}
                                                >
                                                    <div className={`g_text size1 ${CSS.l_media_card_file_name_container}`}>
                                                        {item.file_name}
                                                    </div>
                                                    {
                                                        !readonly
                                                        &&  <div 
                                                                className={`g_icon g_click_event_item ${CSS.l_remove_img_btn}`}
                                                                onClick={handleRemove.bind(null, index)}
                                                            >
                                                                X
                                                            </div>
                                                    }
                                                </MediaCard>
                                            </Tooltip>
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                    </DragAndDrop>
                )
                break;
            default :
                ;
        }
    }, [readonly, imgList])

    // 데이터 초기화
    const removeData = () => {
        setSelectedOptionList([]);
        
        upload_input_btn_container_context.setAlbumData({
            baby_name: selectedProduct.name || "",
            baby_birth: selectedProduct.birth || "",
            baby_birth_time: selectedProduct.birth_time || "",
            tall: selectedProduct.height || "",
            weight: selectedProduct.weight || "",
        })

        upload_input_btn_container_context.setRequest(selectedProduct.common_memo || "");

        upload_input_btn_container_context.setRowAddressData(null);        

        let address_data_is_set:boolean = false;
        if(selectedProduct.zip_code && selectedProduct.addr && selectedProduct.building_no){
            address_data_is_set = true;
        }
        
        upload_input_btn_container_context.setCustomAddressData((item) => {
            return({
                ...item,
                zonecode: selectedProduct.zip_code || "",
                mainAddress: selectedProduct.addr || "",
                buildingCode: selectedProduct.building_no || "",
                is_set: address_data_is_set,
            })
        })
        upload_input_btn_container_context.setDetailAddress(selectedProduct.addr_detail || "");
    }

    // 모든 사진 제거
    const removeImgAll = () => {
        if(selectedFileListRef){
            selectedFileListRef.current = [];
        }
        setPreviewUrls([]);
    }

    // 모든 사진 제거 버튼 헨들러
    const handleRemoveAll = () => {
        if(selectedFileListRef && selectedFileListRef.current.length > 0){            
            if(!window.confirm("업로드된 모든 사진을 제거하시겠습니까?")){
                return ;
            }
        }

        removeImgAll();
    }

    const handleClick = () => {
        uploadInputRef.current?.click();
    };

    // 특정 사진을 제거하는 함수
    const handleRemove = (index: number) => {
        if(!window.confirm("제거하시겠습니까?")){
            return ;
        }

        if(selectedFileListRef){
            const new_list = selectedFileListRef.current.filter((_, i) => i !== index);
            selectedFileListRef.current = new_list;
        }

        const newUrls = previewUrls.filter((_, i) => i !== index);
        setPreviewUrls(newUrls);
    };
    
    // 모달 닫기버튼 동작
    const handleCloseModal = () => {
        if(readonly){
            setIsModalOpen(false);
            return ;
        }

        if(!window.confirm("요청이 완료되지 않았습니다. 창을 닫으시겠습니까?")){
            return ;
        }
        
        removeImgAll();
        removeData();
        setIsModalOpen(false);
    }

    // 서버에 업로드 요청
    const handleUpload = async () => {
        if(selectedFileListRef && selectedFileListRef.current.length === 0){
            customToast.error("사용하실 사진을 업로드해주세요.");
            return;
        } 

        if(isNaN(quantity_of_photos_to_choose.min) || isNaN(quantity_of_photos_to_choose.max)){
            customToast.error("선택해야할 사진의 수가 설정되지 않았습니다.\n계약하신 지점으로 문의해주세요.");
            return ;
        }

        if(quantity_of_photos_to_choose.min < 1 || quantity_of_photos_to_choose.max < 1){
            customToast.error("선택해야할 사진의 수가 설정되지 않았습니다.\n계약하신 지점으로 문의해주세요.");
            return ;
        }

        if(quantity_of_photos_to_choose.max < quantity_of_photos_to_choose.min){
            customToast.error("사진 선택관련 데이터에 문제가있습니다.\n계약하신 지점으로 문의해주세요.");
            return ;
        }

        if(!isMeetTheConditions){
            customToast.error(`최소 ${quantity_of_photos_to_choose.min}장에서 최대 ${quantity_of_photos_to_choose.max}장의 사진이 필요합니다.\n(현재 ${selectedFileListRef ? selectedFileListRef.current.length : 0}장)`);
            return ;
        }

        if(photo_select_context.selectedProductOptionDataList.length> 0 && selectedOptionList.length === 0){
            customToast.error("옵션을 선택해주세요.(필수 택 1)");
            return ;
        }
        
        if(!upload_input_btn_container_context.customAddressData.is_set){
            customToast.error("배송지를 입력해주세요.");
            return ;
        }

        const final_msg_list: string[] = ["상품제작을 위한 사진을 모두 선택하셨습니까?", "제품 제작이 시작되면 입력하신 내용과 선택하신 사진 변경이 불가능합니다."];
        if(!window.confirm(final_msg_list.join(`\n`))){
            return ;
        }else if(!window.confirm(`사진선택을 완료하시겠습니까?`)){      
            return ;
        }

        const file_dir = `media/office/${selectedProduct.office_id}/customers/${selectedProduct.customer_id}/selectPhoto/contract/${selectedProduct.contract_id}/products/${selectedProduct.product_id}`;

        photo_select_context.setIsUploading(true);

        if(selectedFileListRef){
            imageFilesUpload({
                files: selectedFileListRef.current,
                file_dir,
            })
            .then((response) => {
                if(response.state === 200){
                    const file_name = response.data.map((item) => item.file_name);
                    const file_ext = response.data.map((item) => item.file_extension);
                    const file_size = response.data.map((item) => item.file_size);

                    const address = upload_input_btn_container_context.customAddressData;

                    const config = {
                        contract_product_id: selectedProduct.contract_product_id,
                        contract_id: `${selectedProduct.contract_id}`,
                        office_id: selectedProduct.office_id,
                        baby_name: upload_input_btn_container_context.albumData.baby_name,
                        baby_birth: upload_input_btn_container_context.albumData.baby_birth,
                        album_save_id: isReRequest ? `${selectedProduct.album_save_id}` : "",
                        thema_id_list: selectedOptionList.map((item) => item.thema_id).join(","),
                        height: `${upload_input_btn_container_context.albumData.tall}`,
                        weight: `${upload_input_btn_container_context.albumData.weight}`,
                        birth_time: upload_input_btn_container_context.albumData.baby_birth_time,
                        addr: address.mainAddress,
                        addr_detail: upload_input_btn_container_context.detailAddress,
                        building_no: address.buildingCode,
                        zip_code: address.zonecode,
                        common_memo: upload_input_btn_container_context.request,
                        file_url: file_dir,
                        file_name: file_name,
                        file_ext: file_ext,
                        file_size: file_size
                    }

                    dev_console.log(config);

                    const photo_select_upload_new_url = "api/v3/customers/photo-select-upload-new";
                    callAxios.api({
                        url: photo_select_upload_new_url,
                        method: "post",
                        dataType: "json",
                        data: config,
                    })
                    .then((response) => {
                        dev_console.log(photo_select_upload_new_url);
                        dev_console.log(response);
            
                        const response_data: defaultApiReaponseInterface<undefined> = response.data;

                        if(isSuccess(response)){
                            alert("사진 업로드가 완료되었습니다.");
                            if(isUseTestData()){
                                customToast.info("개발 - 테스트모드 이므로 새로고침 시키지 않습니다. - 개발");
                            }else{
                                window.location.reload();
                            }
                        }else if(response_data.status.code === 201){
                            customToast.error("사진 재선택이 불가능한 상품입니다.\n사유 : 이미 제품 제작이 시작된 상품입니다.");
                        }else{
                            printStateMsg(response);
                            customToast.error("사진 업로드에 실패했습니다.")
                        }
                    })
                    .catch((error) => {
                        dev_console.error(error);
                        customToast.error("사진 업로드 과정에서 문제가 발생했습니다.");
                    })
                }
            })
            .catch((error) => {
                dev_console.error(error);
            })
            .finally(() => {
                photo_select_context.setIsUploading(false);
            })
        }
    };

    return (
        <Modal
            isModalOpen={isModalOpen}
            modalCloseHandler={handleCloseModal}
        >
            <div className={`g_card ${CSS.l_uploaded_image_wrap_modal_main}`}>
                <div className={CSS.l_uploaded_image_wrap_modal__main_container}>
                    
                    {/* 테마 목록 출력부분 */}
                    {node}
                    
                    {
                        photo_select_context.selectedProductOptionDataList.length > 0
                        &&  <div className={CSS.l_uploaded_image_wrap_modal__option_container}>
                                <InputTitle 
                                    title="옵션"
                                    sub_title="필수선택(택 1)"
                                />
                                <div className={CSS.l_uploaded_image_wrap_modal__option_container__option_item_container}>
                                    <OptionsContainer
                                        selectedProductOptionDataList={photo_select_context.selectedProductOptionDataList}
                                        selectedOptionList={selectedOptionList}
                                        setSelectedOptionList={setSelectedOptionList}
                                        disabled={readonly}
                                    />
                                </div>
                            </div>
                    }
                    <div className={CSS.l_uploaded_image_wrap_modal__input_container}>
                        {
                            is_album
                            &&  <AlbumProductInputContainer 
                                    disabled={readonly}
                                />
                        }

                        <RequestTextArea 
                            request={upload_input_btn_container_context.request}
                            setRequest={upload_input_btn_container_context.setRequest}
                            disabled={readonly}
                        />

                        <AddressContainer 
                            customAddressData={upload_input_btn_container_context.customAddressData} 
                            setRowAddressData={upload_input_btn_container_context.setRowAddressData}
                            detailAddress={upload_input_btn_container_context.detailAddress} 
                            setDetailAddress={upload_input_btn_container_context.setDetailAddress}
                            disabled={readonly}
                        />
                    </div>
                </div>
                {
                    readonly !== true
                    &&  <div className="g_btn_wrap">
                            <Button 
                                size={"size1"}
                                onClick={handleClick}
                            >
                                사진 추가
                            </Button>

                            <Button 
                                size={"size1"}
                                purpose={"delete"}
                                onClick={handleRemoveAll}
                            >
                                모든사진 삭제
                            </Button>

                            <TextFlexWrap
                                classNameList={
                                    isMeetTheConditions
                                    ?   [CSS.l_upload_status_text, CSS.meet_the_conditions]
                                    :   undefined     
                                }
                                text_list={[`최소 ${quantity_of_photos_to_choose.min}장에서`,`최대 ${quantity_of_photos_to_choose.max}장의 사진이 필요합니다.`,`(현재 ${selectedFileListRef ? selectedFileListRef.current.length : 0}장)`]}
                            />

                            <Button
                                purpose={"save"}
                                onClick={handleUpload}
                            >
                                {
                                    isReRequest
                                    ?   "재신청"
                                    :   "제품 제작 신청"
                                }
                            </Button>
                        </div>
                }
            </div>
        </Modal>
    )
}

export default UploadedImageContainerModal;