import CSS from "./static/css/customerPageHome.module.css";
import MainSchedule from "./components/MainSchedule";
import ProductList from "./components/ProductList";
import AdvertisingBanner from "../../../CustomerPage/AdvertisingBanner/AdvertisingBanner";
import ScheduleCalendar from "./components/ScheduleCalendar/ScheduleCalendar";
import FinalApproval from "./components/FinalApproval/FinalApproval";

const CustomerPageHome = () => {

    return (
        <div className={CSS.l_home_main}>
            <div className={`${CSS.grid_item} ${CSS.l_main_schedule_container}`}>
                <MainSchedule />
            </div>
            <div className={`${CSS.grid_item} ${CSS.l_calendar_container}`}>
                <ScheduleCalendar />
            </div>
            <div className={`${CSS.grid_item} ${CSS.l_advertising_banner_container}`}>
                <AdvertisingBanner />
            </div>
            <div className={`${CSS.grid_item} ${CSS.l_product_container}`}>
                <ProductList />
                <FinalApproval />
            </div>
        </div>
    )
}

export default CustomerPageHome;