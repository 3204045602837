import { useEffect, useState } from "react";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import WideCard from "../../../../Component/Elements/WideCard/WideCard";
import { contractListDataInterace, docsImgDataInterface } from "../../interface/ContractDocs.interface";
import CSS from "./static/css/DocsImgList.module.css";
import DocsThumbnailItem from "../DocsThumbnailItem/DocsThumbnailItem";
import Modal from "../../../../Component/Elements/Modal/Modal";
import Img from "../../../../Component/Elements/Media/components/Img";
import Button from "../../../../Component/Elements/Button/Button";
import { downloadFile } from "../../../../Common/ts/commonTools";

const DocsImgList = (
    {
        selectedContractDocs
    }:{
        selectedContractDocs: contractListDataInterace | null;
    }
) => {
    const [selectedDocsImgSrc, setSelectedDocsImgSrc] = useState<string>("");

    const [innerContainerNode, setInnerContainerNode] = useState<React.ReactNode | null>(null);

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const modalOpenHandler = () => {
        setIsOpenModal(true);
    }

    const modalCloseHandler = () => {
        setIsOpenModal(false);
    }

    const docsOnClickHander = (src: string) => {
        setSelectedDocsImgSrc(src);
        modalOpenHandler();
    }

    useEffect(() => {
        let state: number = 0;

        if(selectedContractDocs){
            if(selectedContractDocs.img_list.length > 0){
                state = 1;
            }else{
                state = -1;
            }
        }else{
            state = -2;
        }

        switch(state){
            case 1:
                setInnerContainerNode(
                    <div className={CSS.l_docs_img_list__inner_container}>
                        {
                            selectedContractDocs?.img_list.map((item, index) => {
                                return(
                                    <DocsThumbnailItem 
                                        key={index}
                                        docs_img_src={item.small_url}
                                        onClick={docsOnClickHander.bind(null, item.org_url)}
                                    />
                                )
                            })
                        }
                    </div>
                );
                break;
            case -1:
                // 문서 배열이 비어있음
                setInnerContainerNode(
                    <WideCard>
                        {"업로드된 계약서가 없습니다."}
                    </WideCard>
                );
                break;
            case -2:
                // 계약이 선택되지 않음
                setInnerContainerNode(
                    <WideCard>
                        {"계약이 선택되지 않았습니다."}
                    </WideCard>
                );
                break;
            default:
                setInnerContainerNode(null);
        }
    }, [selectedContractDocs])

    return(
        <CardContainer class_name_list={[CSS.l_docs_img_list_main]}>

            {innerContainerNode}
            
            {/* 계약서 상세보기용 모달 */}
            <Modal
                isModalOpen={isOpenModal}
                modalCloseHandler={modalCloseHandler}
            >
                <div className={CSS.l_modal_contents_container}>
                    <div className={CSS.l_modal_contents_container__img_cover}>
                        <Img 
                            src={selectedDocsImgSrc}
                            object_fit={"contain"}
                            class_name_list={[CSS.l_modal_contents_container__img_cover__img]}
                        />
                    </div>
                    <Button
                        size={"size1"}
                        onClick={() => {
                            downloadFile(selectedDocsImgSrc, `contract_docs.jpg`);
                        }}
                        purpose={"dev"}
                    >
                        다운로드
                    </Button>
                </div>
            </Modal>
        </CardContainer>
    )
}

export default DocsImgList;