import usePhotoListNaviBadge from "./hooks/usePhotoListNaviBadge.hook";
import usePhotoProductRequestNaviBadge from "./hooks/usePhotoProductRequestNaviBadge.hook";
import useProgressScheduleNaviBadge from "./hooks/useProgressScheduleNaviBadge.hook";
import Navi from "./Navi";

// 네비게이션바 이미지
import schedule_list_icon from "../../PublicPage/Navi/static/img/Group 17279.svg";
import photo_icon from "../../PublicPage/Navi/static/img/Group 17280.svg";
import photos_icon from "../../PublicPage/Navi/static/img/Group 17281.svg";
import bag_icom from "../../PublicPage/Navi/static/img/bag-03.svg";
import home_icon_disabled from "../../PublicPage/Navi/static/img/home_icon_disabled.svg";
import user_icon from "../../PublicPage/Navi/static/img/user_icon.svg";

// 네비게이션바 이미지(활성상태)
import schedule_list_icon_activated from "../../PublicPage/Navi/static/img/Group 17279 activated.svg";
import photo_icon_activated from "../../PublicPage/Navi/static/img/Group 17280 activated.svg";
import photos_icon_activated from "../../PublicPage/Navi/static/img/Group 17281 activated.svg";
import bag_icom_activated from "../../PublicPage/Navi/static/img/bag-03 activated.svg";
import home_icon from "../../PublicPage/Navi/static/img/home_icon.svg";
import user_icon_activated from "../../PublicPage/Navi/static/img/user_icon activated.svg";

const CustomerNavi = (
    {
        isOpenMenu,
        setIsOpenMenu
    }:{
        isOpenMenu: boolean
        setIsOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
    }
) => {
    // 내 촬영일정에 badge가 출력되야할 항목이 있는가?
    const { isProgressScheduleBadge } = useProgressScheduleNaviBadge();

    // 고객의 제품 정보
    const { isPhotoProductNaviBadge } = usePhotoProductRequestNaviBadge();

    // 내 사진에 badge가 출력되야할 항목이 있는가?
    const { isPhotoListNaviBadge } = usePhotoListNaviBadge();

    return(
        <Navi 
            isOpenMenu={isOpenMenu}
            setIsOpenMenu={setIsOpenMenu}
            navi_item_list={[
                {
                    name: "홈",
                    img_src: home_icon_disabled,
                    activated_img_src: home_icon,
                    navigate: "/",
                    is_badge_on: false,
                },
                {
                    name: "촬영 일정",
                    img_src: schedule_list_icon,
                    activated_img_src: schedule_list_icon_activated,
                    navigate: "/reservationHome",
                    is_badge_on: isProgressScheduleBadge,
                },                                
                {
                    name: "내 사진",
                    img_src: photos_icon,
                    activated_img_src: photos_icon_activated,
                    navigate: "/photoList",
                    is_badge_on: isPhotoListNaviBadge,
                },                                
                {
                    name: "제품 제작 신청",
                    img_src: photo_icon,
                    activated_img_src: photo_icon_activated,
                    navigate: "/photoSelect",
                    is_badge_on: isPhotoProductNaviBadge,
                },                                
                {
                    name: "테마북",
                    img_src: bag_icom,
                    activated_img_src: bag_icom_activated,
                    navigate: "/themeBook",
                    is_badge_on: false,
                },
                {
                    name: "계약서 확인",
                    img_src: user_icon,
                    activated_img_src: user_icon_activated,
                    navigate: "/contractDocs",
                    is_badge_on: false,
                }, 
            ]}
        />
    )
}

export default CustomerNavi;