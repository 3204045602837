import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataInfo from "../../Component/Elements/DataInfo/DataInfo";
import { progressScheduleInterface } from "./static/interface/reservationHome.interface";
import { customToast } from "../../Common/ts/commonTools";
import { contractScheduleReservationConfigInterface, selectedThemeListInterface } from "./static/interface/selectTheme.interface";
import CSS from "./static/css/selectTheme.module.css";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import Button from "../../Component/Elements/Button/Button";
import ThemeListContainer from "./components/ThemeListContainer";
import TextFlexWrap from "../../Component/Elements/TextFlexWrap/TextFlexWrap";
import CardContainer from "../../Component/Elements/CardContainer/CardContainer";
import useReservationInfoData from "../../Component/Elements/DataInfo/hooks/useReservationInfoData.hook";
import useThemeDataREST from "./hooks/useThemeDataREST.hook";
import useSelectedThemeSubmitREST from "./hooks/useSelectedThemeSubmitREST.hook";

const SelectTheme = () => {

    // 선택된 테마 정보(테마 선택을 위해 타입을 추가한 데이터)
    const [selectedThemeList, setSelectedThemeList] = useState<selectedThemeListInterface[]>([]);
    
    const [btnNode, setBtnNode] = useState<React.ReactNode | null>(null);

    const {
        infoDataList,
        setOfficeName,
        setScheduleData
    } = useReservationInfoData();
    
    const location = useLocation();
    const data = location.state as { schedule_info_for_select_theme: progressScheduleInterface };
    
    const navigator = useNavigate();

    const {
        themeData,
        themeDataIsLoading,
        setScheduleId,
        setThemaIdList,
    } = useThemeDataREST();

    const { selectedThemeSubmitMutate } = useSelectedThemeSubmitREST();

    // 읽기전용
    const isReadOnly = !!data && data.schedule_info_for_select_theme.btn_type !== 0

    useEffect(() => {
        if(data && data.schedule_info_for_select_theme){
            setScheduleData(data.schedule_info_for_select_theme);
            setScheduleId(data.schedule_info_for_select_theme.schedule_id);
            if(data.schedule_info_for_select_theme.detail){
                setThemaIdList(data.schedule_info_for_select_theme.detail.thema_id_list);
            }
        }else{
            alert("잘못된 접근입니다.");
            navigator("/reservationHome");
        }
    }, [data])

    useEffect(() => {
        if(themeData){
            if(themeData.schedule_data){
                setOfficeName(themeData.schedule_data.office_name);
            }else{
                setOfficeName("")
            }

            setSelectedThemeList(themeData.theme_data_list);
        }else{
            setOfficeName("");
            setSelectedThemeList([]);
        }
    }, [themeData])

    useEffect(() => {
        let state: number = 0;

        if(selectedThemeList.length === 0){
            state = -1;
        }else if(isReadOnly){
            state = 2;
        }else{
            state = 1;
        }

        switch(state){
            case 1:
                setBtnNode(
                    <Button
                        size={"size_full"}
                        onClick={onSubmit}
                        purpose={"save"}
                    >
                        선택 완료
                    </Button>
                );
                break;
            case 2:
                setBtnNode(
                    <TextFlexWrap
                        classNameList={["g_text_wrap", "warning", "g_text", "size1"]}
                        text_list={["테마를 변경하고 싶으시다면 지점에 직접 문의하시거나 촬영하는 날 촬영 시작 전에 말씀해주세요"]}
                    />
                );
                break;
            case -1:
                setBtnNode(
                    <Button
                        size={"size_full"}
                        purpose={"disabled"}
                    >
                        테마를 선택해야할 촬영상품이 없습니다.
                    </Button>
                );
                break;
            default:
                setBtnNode(null);
                ;
        }
    }, [isReadOnly, selectedThemeList])

    const onSubmit = () => {
        const config: contractScheduleReservationConfigInterface = {
            schedule_id: "0",
            shoot_type: 0,
            photo_concept: [],
            photo_id: [],
        }

        if(themeData){
            if(themeData.schedule_data){
                config.schedule_id = `${themeData.schedule_data.schedule_id}`;
                config.shoot_type = themeData.schedule_data.shoot_type;
            }
        }

        let photo_id_list:string[] = [];
        let thema_id_list:string[] = [];

        // 선택이 전혀 안된 촬영 목록
        const non_selected_photo_index_list: number[] = [];

        const alert_msg: string[] = ["선택하신 테마를 확인해주세요.", `\n`];
        for(let index = 0 ; index < selectedThemeList.length ; index++){
            const theme = selectedThemeList[index];

            let theme_alert_string: string = `${theme.photo_name}: `;
            const theme_alert_msg: string[] = [];

            if(theme.selected_theme_list.length === 0){
                non_selected_photo_index_list.push(index);
            }

            for(const selected_theme of theme.selected_theme_list){
                photo_id_list.push(theme.photo_id);
                thema_id_list.push(`${selected_theme.thema_id}`);
                theme_alert_msg.push(selected_theme.thema_name);
            }
            theme_alert_string += theme_alert_msg.join(", ");
            alert_msg.push(theme_alert_string)
        }

        if(non_selected_photo_index_list.length > 0){
            if(non_selected_photo_index_list.length === selectedThemeList.length){
                customToast.error("최소 1개 이상의 테마를 선택해주세요.")
                return ;
            }else if(!window.confirm("테마를 선택하지 않은 촬영이 존재합니다.\테마를 선택하지 않고 이대로 완료 하시겠습니까?")){
                return ;
            }
        }

        // 완성된 안내 문장을 출력한다.
        alert(alert_msg.join(`\n`));

        config.photo_id = [...photo_id_list];
        config.photo_concept = [...thema_id_list];

        const confirm_msg: string[] = ["촬영 컨셉 선택을 완료하시겠습니까?", `\n`, "선택이 완료된 후에 컨셉 변경을 원하실경우", "촬영당일 현장에서 직접 요청하셔야합니다."];
        if(!window.confirm(confirm_msg.join(`\n`))){
            return ;
        }

        selectedThemeSubmitMutate({ config }, {
            onSuccess: (data, variables, context) => {
                if(data.status === 200){
                    customToast.success("선택하신 태마가 저장되었습니다.");
                    navigator("/reservationHome");
                }else{
                    customToast.error("선택하신 테마를 저장하는 과정에서 문제가 발생했습니다."); 
                }
            },
            onError: (error, variables, context) => {
                customToast.error("선택하신 테마를 저장하는 과정에서 문제가 발생했습니다.");
            },
        });
    }

    return (
        <main className={`${CSS.l_select_theme_main}`}>
            <div className="g_title">테마 선택</div>
            <div className={`${CSS.l_theme_main_contents}`}>
                <CardContainer
                    class_name_list={[CSS.l_card_outline, CSS.l_theme_left_wrap]}
                >
                    <ThemeListContainer 
                        isReadOnly={isReadOnly}
                        selectedThemeList={selectedThemeList}
                        setSelectedThemeList={setSelectedThemeList}
                    />
                </CardContainer>

                <div className={`${CSS.l_theme_right_wrap}`}>
                    <div className={CSS.l_theme_right_wrap__top}>
                        <DataInfo 
                            infoData={infoDataList}
                        />
                    </div>
                    <div className={CSS.l_theme_right_wrap__bottom}>
                        {btnNode}
                    </div>
                </div>

            </div>
            <LoopAnimationModal
                isModalOpen={themeDataIsLoading}
            />
        </main>
    )

}

export default SelectTheme
