import { createContext, useContext, useEffect, useState } from "react";
import ChangePassword from "./ChangePassword";
import MyProfile from "./MyProfile";
import { isAbleAccesser } from "../../Common/ts/commonTools";
import CSS from "./static/css/myInfo.module.css";

interface myInfoContextInterface {
    setIsPasswordChageMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const myInfoContextDefulteValue: myInfoContextInterface = {
    setIsPasswordChageMode: () => {},
}

export const myInfoContext = createContext<myInfoContextInterface>(myInfoContextDefulteValue);

function MyInfo() {
    const [isPasswordChageMode, setIsPasswordChageMode] = useState<boolean>(false);

    useEffect(() => {
        isAbleAccesser(["C"]);
    }, [])

    return (
        <main className={CSS.l_my_info_main}>
            <myInfoContext.Provider 
                value={{
                    setIsPasswordChageMode
                }}
            >
                {
                    isPasswordChageMode
                    ?   <ChangePassword />   
                    :   <MyProfile />
                }
            </myInfoContext.Provider>
        </main>
    );
}
export default MyInfo;