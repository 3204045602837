import { useEffect, useState } from "react";
import { regionInterface } from "../../../Common/interface/Common.interface";
import { REGION_LIST } from "../../../Common/ts/const";
import { themaBookPhotoListInterface } from "../static/interface/themeBook.interface";
import { customToast, dev_console, findRegionByAddress, getMyCookieData, whoAmI } from "../../../Common/ts/commonTools";
import usePhotoProductDataREST from "./usePhotoProductDataREST.hook";
import useOfficeDataREST from "./useOfficeDataREST.hook";
import useThemeDataREST from "./useThemeDataREST.hook";

const useOffiecListFromRegion = () => {

    const [selectedRegion, setSelectedRegion] = useState<regionInterface | null>(null);
    const [selectedOfficeId, setSelectedOfficeId] = useState<number>(0);
    const [selectedPhotoProduct, setSelectedPhotoProduct] = useState<themaBookPhotoListInterface | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const {
        officeList,
        officeListError,
        isOfficeListLoading,
    } = useOfficeDataREST({
        selectedRegion
    })
    
    const {
        photoProductList, 
        photoProductError, 
        isPhotoProductLoading,
    } = usePhotoProductDataREST({
        office_id: selectedOfficeId
    })

    const {
        themeList,
        themeListError,
        isThemeListLoading,
    } = useThemeDataREST({
        photo_product: selectedPhotoProduct,
        office_id: selectedOfficeId,
    })

    useEffect(() => {
        if (officeList && officeList.length > 0) {
            const customer_office_id: number = getMyCookieData.office_id();
            if(officeList.some((item) => item.office_id === customer_office_id)){
                setSelectedOfficeId(customer_office_id);
            }else{
                setSelectedOfficeId(officeList[0].office_id);
            }
        } else {
            setSelectedOfficeId(0);
        }
    }, [officeList]);

    useEffect(() => {
        if (photoProductList && photoProductList.length > 0) {
            setSelectedPhotoProduct(photoProductList[0]);
        } else {
            setSelectedPhotoProduct(null);
        }
    }, [photoProductList]);

    useEffect(() => {
        // 관리자 계정일때는 해당 관리자의 지점으로 고정한다.
        if(whoAmI.isAdmin()){
            const office_id = getMyCookieData.office_id();
            setSelectedOfficeId(office_id);
        }else if(whoAmI.isCustomer()){
            if(REGION_LIST.length > 0){

                const customer_office_address = getMyCookieData.office_addr();
                const office_region = findRegionByAddress(customer_office_address);
                if(!!office_region){
                    setSelectedRegion(office_region);
                }else{
                    setSelectedRegion(REGION_LIST[0]);
                }
            }else{
                setSelectedRegion(null);
            }
        }
    }, [])

    useEffect(() => {
        setIsLoading(isOfficeListLoading || isPhotoProductLoading || isThemeListLoading);
    }, [isOfficeListLoading, isPhotoProductLoading, isThemeListLoading])

    useEffect(() => {
        setIsError(!!officeListError || !!photoProductError || !!themeListError);
        if(officeListError){
            customToast.error("지점 정보를 요청하는 과정에서 문제가 발생했습니다.");
            dev_console.error(officeListError);
        }

        if(photoProductError){
            customToast.error("촬영상품 정보를 요청하는 과정에서 문제가 발생했습니다.");
            dev_console.error(photoProductError);
        }

        if(themeListError){
            customToast.error("테마 정보를 요청하는 과정에서 문제가 발생했습니다.");
            dev_console.error(themeListError);
        }
    }, [officeListError, photoProductError, themeListError])

    return({
        REGION_LIST,
        selectedRegion,
        setSelectedRegion,
        officeList,
        selectedOfficeId,
        setSelectedOfficeId,
        photoProductList,
        selectedPhotoProduct,
        setSelectedPhotoProduct,
        themeList,
        isLoading,
        isError,
    })
}

export default useOffiecListFromRegion;