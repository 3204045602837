import Text from "../../Component/Elements/Text/Text";
import DocsList from "./components/DocsList/DocsList";
import CSS from "./static/css/ContractDocs.module.css";
import DocsImgList from "./components/DocsImgList/DocsImgList";
import useContractDocs from "./hooks/useContractDocs.hook";
import { useEffect } from "react";
import { isAbleAccesser } from "../../Common/ts/commonTools";

const ContractDocs = () => {

    const {
        contractList,
        selectedContractDocs, 
        setSelectedContractDocs,
    } = useContractDocs();

    useEffect(() => {
        isAbleAccesser(["C"]);
    }, [])

    return(
        <div className={CSS.l_contract_docs_main}>
            <div className={CSS.l_contract_docs__title_container}>
                <Text
                    size={"size7"}
                    color={"dark"}
                >
                    계약서
                </Text>
            </div>
            <div className={CSS.l_contract_docs__contents}>
                <div className={CSS.l_contract_docs__contents__docs_list}>
                    <DocsList 
                        contractList={contractList}
                        selectedContractDocs={selectedContractDocs}
                        setSelectedContractDocs={setSelectedContractDocs}   
                    />
                </div>
                <div className={CSS.l_contract_docs__contents__docs}>
                    <DocsImgList 
                        selectedContractDocs={selectedContractDocs}
                    />
                </div>
            </div>
        </div>
    )
}

export default ContractDocs;