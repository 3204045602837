import { useEffect, useState } from "react";
import { imageDataInterface, locationStateDataInterface } from "../static/interface/PhotoDetail.interface";
import usePhotoDetailDataREST from "./usePhotoDetailDataREST.hook";
import useDateString from "../../../Common/hooks/useDateString.hook";
import { customerConfirmedType, joinedEventType, managerConfirmedType } from "../static/interface/PhotoList.interface";

const usePhotoDetail = (
    {
        location_state_data
    }:{
        location_state_data: locationStateDataInterface
    }
) => {
    // 사진 데이터
    const [imageList, setImageList] = useState<imageDataInterface[]>([]);
    // 선택된 사진
    const [seletedData, setSeletedData] = useState<imageDataInterface |null>(null);
    // 다운로드 버튼이 출력될 수 있는가?
    const [isPrintDownloadBtn, setIsPrintDownloadBtn] = useState<boolean>(false);
    // 다운로드 버튼에 badge가 출력될 수 있는가?
    const [isPrintDownloadBtnBadge, setIsPrintDownloadBtnBadge] = useState<boolean>(false);
    // 선택된 사진의 index
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    // 촬영날짜를 한글로 보기 좋게 변환
    const { dateString: scheduleDateString, setOriginDateData: setScheduleOriginDateData } = useDateString(null);

    // 촬영시간
    const [photoShootTime, setPhotoShootTime] = useState<{
        start_time: string;
        end_time: string;
    }>({
        start_time: "",
        end_time: "",
    });

    // 파일 수
    const [fileLength, setFileLength] = useState<number>(0);

    // 업로드 날짜를 한글로 보기 좋게 변환
    const { dateString: uploadDateString, setOriginDateData: setUploadOriginDateData } = useDateString(null);

    // 다운로드 날짜를 한글로 보기 좋게 변환
    const { dateString: downloadDateString, setOriginDateData: setDownloadOriginDateData } = useDateString(null);

    const main_type = location_state_data.main_type;
    const viewRange_type = location_state_data.viewRange_type;
    const photo_type = location_state_data.photo_type;
    const selected_photo_upload_id = location_state_data.selected_photo_upload_id;

    let download_type_code = 0;
    if(parseInt(location_state_data.main_type) === 1){
        download_type_code = 1;
    }else if(parseInt(location_state_data.main_type) === 2){
        download_type_code = 3;
    }

    let photo_title: string = location_state_data.viewRange_type === "1" ? "촬영명" : "상품명";

    let isEventApplication: boolean = false;
    if(location_state_data.main_type === "1" && location_state_data.viewRange_type === "1"){
        isEventApplication = true;
    }

    const {
        photoDetailData,
        photoDetailDataIsLoading,
    } = usePhotoDetailDataREST({
        photo_upload_id: parseInt(selected_photo_upload_id),
        main_type: parseInt(main_type),
    })

    useEffect(() => {
        if(!!photoDetailData){

            if(photoDetailData.img_list){
                if(photoDetailData.img_list.length > 0){
                    // 사진 데이터
                    setImageList(photoDetailData.img_list);
    
                    // 초기 이미지 설정
                    setSeletedData(photoDetailData.img_list[0]);
                }

                // 총 파일 수
                setFileLength(photoDetailData.img_list.length);
            }

            // 촬영일자
            setScheduleOriginDateData(photoDetailData.schedule_date);
            // 다운로드일자
            setDownloadOriginDateData(photoDetailData.download_at);
            // 업로드일자
            setUploadOriginDateData(photoDetailData.upload_date);

            // 다운로드버튼 badge설정 조건
            let state: number = 0;
            if(!photoDetailData.download_at || photoDetailData.download_at.length === 0){
                if(main_type === "1"){
                    if(viewRange_type === "1" || viewRange_type === "3"){
                        state = 1;
                    }
                }else if(main_type === "2"){
                    state = 1;
                }
            }

            switch(state){
                case 1:
                    setIsPrintDownloadBtnBadge(true);
                    break;
                default:
                    ;
            }

            // 다운로드버튼 출력 조건 
            let is_print_download_btn: boolean = false;
            if(photoDetailData && photoDetailData.img_list && photoDetailData.img_list.length > 0 && photoDetailData.s3_url){
                is_print_download_btn = true;
            }
            setIsPrintDownloadBtn(is_print_download_btn)

            setPhotoShootTime({
                start_time: photoDetailData.start_time || "",
                end_time: photoDetailData.end_time || "",
            });
        }else{
            setImageList([])
            setSeletedData(null);
            setFileLength(0);
            setScheduleOriginDateData("");
            setDownloadOriginDateData("");
            setUploadOriginDateData("");
            setIsPrintDownloadBtnBadge(false);
            setIsPrintDownloadBtn(false);
            setPhotoShootTime({
                start_time: "",
                end_time: "",
            });
        }
    }, [photoDetailData])

    useEffect(() => {
        if(photoDetailData && photoDetailData.img_list && photoDetailData.img_list.length > 0){
            setSeletedData(photoDetailData.img_list[selectedIndex])
        }
    }, [selectedIndex, photoDetailData])
    
    return({
        photo_type,
        download_type_code,
        photo_title,

        selectedIndex,
        setSelectedIndex,

        isEventApplication,
        scheduleId: `${photoDetailData?.schedule_id || ""}`,
        photoDetailDataIsLoading,
        upload_date: photoDetailData?.upload_date || "",
        joined_event: photoDetailData?.joined_event as joinedEventType,
        photo_upload_id: photoDetailData?.photo_upload_id || 0,
        customer_confirmed: photoDetailData?.customer_confirmed || null as customerConfirmedType,
        manager_confirmed: photoDetailData?.manager_confirmed || "N" as managerConfirmedType,
        confirm_reason: photoDetailData?.confirm_reason || "",
        request_num: photoDetailData?.request_num || "",
        contract_product_id: `${photoDetailData?.contract_product_id || ""}`,
        s3_url: photoDetailData?.s3_url || "",

        imageList,
        seletedData,
        fileLength,
        scheduleDateString,
        uploadDateString,
        downloadDateString,
        isPrintDownloadBtnBadge,
        isPrintDownloadBtn,

        photoShootName: photoDetailData?.photo_shoot_name || "",
        photoShootTime,
        officeName: photoDetailData?.office_name || "",
        packageName: photoDetailData?.package_name || "",
        contractId: `${photoDetailData?.contract_id || ""}`,
        memo: photoDetailData?.memo || "",
    })
}

export default usePhotoDetail;