import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console, isSuccess, printStateMsg, whoAmI } from "../../../Common/ts/commonTools";
import { useEffect } from "react";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { scheduleProductInterface } from "../static/interface/photoSelect.interface";
import { isNeedToSeletItem } from "../static/ts/photoSelectTools";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";

const search_type_value_list: string[] = [
    "전체",
    "제품 제작 신청 필요",
    "제품 제작 신청 완료"
]

const usePhotoProductDataREST = (
    {
        search_type,
    }:{
        search_type: 0 | 1 | 2; // 0: 전체, 1: 제품제작신청필요, 2: 제품제작신청완료
    }
) => {
    const isAbleToRequest = () => {
        let reuslt: boolean = false;

        if(whoAmI.isCustomer()){
            if(search_type >= 0){
                reuslt = true;
            }
        }

        return reuslt;
    }

    const {
        data: photoProductDataList = [],
        error: photoProductDataListError,
        isLoading: photoProductDataListIsLoading,
        refetch: photoProductDataListRefetch,
    } = useQuery({
        queryKey: ["PotoProductData", search_type],
        queryFn: getScheduleProductData,
        enabled: isAbleToRequest(),
        select: (response) => {
            if(response){
                if(isSuccess(response)){
                    const data: defaultApiReaponseInterface<scheduleProductInterface[]> = response.data;
                    if(search_type === 1){
                        return data.result.filter((item) => isNeedToSeletItem(item));
                    }else{
                        return data.result;
                    }
                }else{
                    printStateMsg(response);
                    customToast.error(`${search_type_value_list[search_type]} 데이터 요청중에 문제가 발생했습니다`);
                    return [];
                }
            }else{
                return [];
            }
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
        refetchOnMount: false,  // 다시 마운트 되더라도 새로운 요청을 보내지 않게 처리
    })

    async function getScheduleProductData(){
        const schedule_product_url = `api/v3/customers/schedule_product?search_type=${search_type}`;

        const response = await callAxios.api({
            method: "get",
            url: schedule_product_url,
        })

        dev_console.log(schedule_product_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        if(!!photoProductDataListError){
            customToast.error("촬영상품정보 호출 과정에서 문제가 발생했습니다.");
        }
    }, [photoProductDataListError])

    return({
        search_type,
        photoProductDataList,
        photoProductDataListIsLoading,
        photoProductDataListRefetch,
    })
}

export default usePhotoProductDataREST;