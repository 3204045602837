import { useEffect, useRef, useState } from "react";
import TextEditerQuill from "../../Component/Elements/TextEditer/TextEditerQuill";
import { isAbleAccesser } from "../../Common/ts/commonTools";
import { themaBookDetailListInterface } from "../../PublicPage/ThemeBook/static/interface/themeBook.interface";
import { useLocation, useNavigate } from "react-router-dom";
import { getDifferentImageUrls } from "../../Component/Elements/TextEditer/static/ts/tools";
import CSS from "./static/css/themeBookManagement.module.css";
import TitleContainer from "../../PublicPage/ThemeBook/components/TitleContainer";
import ThemeDetailModalBtnContainer from "../../PublicPage/ThemeBook/components/ThemeDetailModalBtnContainer";

const ThemeBookManagement = () => {
    
    const [mainContent, setMainContent] = useState<string>("");
    
    const navigate = useNavigate();
    const location = useLocation();
    const selected_sub_theme = location.state as themaBookDetailListInterface;

    // 삭제되야할 이미지 목록
    const removedImageUrlListRef = useRef<string[]>([]);

    useEffect(() => {
        isAbleAccesser(["E"]);

        if(!!selected_sub_theme){
            setMainContent(selected_sub_theme.content || "");
            removedImageUrlListRef.current = getDifferentImageUrls({
                origin: selected_sub_theme.content || "",
                newContent: mainContent,
                conditions: "removed",
            });
        }else{
            navigate("/themeBook");
        }
    }, [])

    return (
        <main className={CSS.l_theme_book_management_main}>
            <div className={CSS.l_theme_book_management__main_container}>
                <div className={CSS.l_title_container}>
                    <TitleContainer 
                        photo_name={selected_sub_theme.photo_name || ""}
                        title={selected_sub_theme.thema_name || ""}
                        branch_name={selected_sub_theme.office_name || ""}
                    />             
                </div>
                <div className={CSS.l_text_editer_quill_container}>
                    <TextEditerQuill
                        read_only={false}
                        content={mainContent}
                        setContent={setMainContent}
                        office_id={selected_sub_theme.office_id}
                        thema_id={selected_sub_theme.thema_id}
                        type={selected_sub_theme.type}
                    />
                </div>
                <div className={CSS.l_btn_container}>
                    <ThemeDetailModalBtnContainer 
                        themeData={selected_sub_theme}
                        isInEditPage={true}
                        removedImageUrlListRef={removedImageUrlListRef}
                        mainContent={mainContent}
                    />
                </div>
            </div>
        </main>
    )
}

export default ThemeBookManagement;