import { useEffect, useState } from "react"
import { isAbleAccesser } from "../../Common/ts/commonTools";
import DataInfo from "../../Component/Elements/DataInfo/DataInfo";
import { progressScheduleInterface } from "./static/interface/reservationHome.interface";
import ScheduleList from "./components/ScheduleList";
import { useNavigate } from "react-router-dom";
import CSS from "./static/css/reservationHome.module.css"
import Button from "../../Component/Elements/Button/Button";
import { setProgressScheduleBtn } from "./static/ts/reservation.tool";
import useReservationInfoData from "../../Component/Elements/DataInfo/hooks/useReservationInfoData.hook";
import TitleBtnWrap from "../../Component/Elements/TitleBtnWrap/TitleBtnWrap";
import useProgressScheduleDataAllScheduleType from "./hooks/useProgressScheduleDataAllScheduleType";
import useProgressScheduleFilterBadge from "./hooks/useProgressScheduleFilterBadge";

const ReservationHome = () => {

    const [statusType, setStatusType] = useState<number>(1);
    const [moveBtnNode, setMoveBtnNode] = useState<React.ReactNode | null>(null);
    const [selectedSchedule, setSelectedSchedule] = useState<progressScheduleInterface | null>(null);

    // 촬영 일정정보를 서버에 요청
    const {
        progressScheduleDataList,
        isLoading,
    } = useProgressScheduleDataAllScheduleType({
        status_type: statusType as 0 | 1 | 2
    })

    // 서버로 부터 온 데이터를 사용하기좋게 편집
    const {
        infoDataList,
        setOfficeName,
        setScheduleData
    } = useReservationInfoData();

    // badge 출력 정보
    const {
        badgeList,
    } = useProgressScheduleFilterBadge();

    const navigate = useNavigate();

    useEffect(() => {
        isAbleAccesser(["C"]);
    }, [])

    useEffect(() => {
        if(selectedSchedule){
            setScheduleData(selectedSchedule);
            setOfficeName(selectedSchedule.detail?.office_name || "");
            const result = setProgressScheduleBtn({item: selectedSchedule, navigate});

            setMoveBtnNode(
                <Button
                    onClick={result.onClick_btn}
                    size={"size_full"}
                >
                    {result.btn_title}
                </Button>
            )
        }else{
            setScheduleData(null);
            setOfficeName("");
            setMoveBtnNode(null);
        }
    }, [selectedSchedule])

    return (
        <div className={CSS.l_reservation_home_main}>
            <div className="g_title">촬영 일정</div>
            <div className={`g_card_wrap ${CSS.l_contents}`}>
                <div className={`g_card ${CSS.l_schedule_card}`}>
                    <TitleBtnWrap<number>
                        valueState={statusType}
                        setValueState={setStatusType}
                        value_list={[0, 1, 2]}
                        innerText_list={["전체보기", "미완료일정", "완료일정"]}
                        is_activated={true}
                        is_print_badge_list={badgeList}
                    />
                    <ScheduleList 
                        progressSchedule={progressScheduleDataList}
                        selectedSchedule={selectedSchedule}
                        setSelectedSchedule={setSelectedSchedule}
                        isLoading={isLoading}
                    />
                </div>
                {
                    selectedSchedule
                    &&  <div className={`g_display_none_object mobile flex ${CSS.l_right_card}`}>
                            <div className={CSS.l_theme_right_wrap__top}>
                                <DataInfo
                                    infoData={infoDataList}
                                />
                            </div>
                            <div
                                className={CSS.l_move_btn}
                            >
                                {moveBtnNode}
                            </div>
                        </div>
                }
            </div>
        </div>
    )

}

export default ReservationHome