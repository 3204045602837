// 컴포넌트 시뮬레이션을을 위한 테스트페이지
import CSS from "./static/css/testPage.module.css";

const TestPage = () => {

    return (
        <div className={CSS.l_test_page_main}>
        </div>
    )
}

export default TestPage