import { forwardRef, useEffect, useState } from "react";
import CSS from "./static/css/button.module.css";
import { btnPaddingType, btnPurposeType, btnSizeType, btnTypeType } from "./static/interface/Button.interface";

interface ButtonInterface{
    children: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    class_name_list?: string[];
    size?: btnSizeType;
    padding?: btnPaddingType;
    purpose?: btnPurposeType;
    type?: btnTypeType;
    disabled?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonInterface>(({
    children,
    onClick,
    class_name_list,
    size,
    purpose,
    padding,
    type,
    disabled,
}, ref) => {
    const [totalClassName, setTotalClassName] = useState<string>("");

    useEffect(() => {
        const total_class_name_list: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name)
            }
        }

        total_class_name_list.push("g_click_event_item");
        if(!onClick){
            total_class_name_list.push("not_allowed");
        }

        total_class_name_list.push(CSS.l_btn);

        if(size){
            total_class_name_list.push(CSS[size]);
        }

        if(padding){
            total_class_name_list.push(CSS.padding);
            total_class_name_list.push(CSS[padding]);
        }

        if(disabled){
            total_class_name_list.push(CSS.purpose);
            total_class_name_list.push(CSS.disabled);
        }else if(purpose){
            total_class_name_list.push(CSS.purpose);
            total_class_name_list.push(CSS[purpose]);
        }

        setTotalClassName(total_class_name_list.join(" "));
    }, [class_name_list, onClick, size, purpose, padding])

    return (
        <button
            className={totalClassName}
            onClick={onClick}
            type={type || "button"}
            disabled={disabled}
            ref={ref}
        >
            {children}
        </button>
    )
})

export default Button