import WideCard from "../../Component/Elements/WideCard/WideCard";
import ImageBlock from "./components/ImageBlock";
import TextFlexWrap from "../../Component/Elements/TextFlexWrap/TextFlexWrap";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import Media from "../../Component/Elements/Media/Media";
import CSS from "./static/css/photoDetail.module.css";
import DownloadBtnContainer from "./components/DownloadBtnContainer";
import CustomerConfirm from "./components/CustomerConfirm/CustomerConfirm";
import EventApplication from "./components/EventApplication/EventApplication";
import PhotoListSwipeLine from "../../Component/Elements/PhotoListSwipeLine/PhotoListSwipeLine";
import Tooltip from "../../Component/Elements/Tooltip/Tooltip";
import Text from "../../Component/Elements/Text/Text";
import { useLocation, useNavigate } from "react-router-dom";
import { locationStateDataInterface } from "./static/interface/PhotoDetail.interface";
import usePhotoDetail from "./hooks/usePhotoDetail.hook";
import { useEffect } from "react";
import { getMyCookieData, isAbleAccesser } from "../../Common/ts/commonTools";

const PhotoDetail = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const location_state_data = location.state as locationStateDataInterface;

    // 필요한 정보가 넘어오지 않았다면, 되돌려보낸다.
    if(!location_state_data || !location_state_data.selected_photo_upload_id || !location_state_data.main_type){
        navigate("/photoList");
    }

    const {
        photo_type,
        download_type_code,
        photo_title,

        selectedIndex,
        setSelectedIndex,

        isEventApplication,
        scheduleId,
        photoDetailDataIsLoading,
        upload_date,
        joined_event,
        photo_upload_id,
        customer_confirmed,
        manager_confirmed,
        confirm_reason,
        request_num,
        contract_product_id,
        s3_url,

        imageList,
        seletedData,
        fileLength,
        scheduleDateString,
        uploadDateString,
        downloadDateString,
        isPrintDownloadBtnBadge,
        isPrintDownloadBtn,
        photoShootName,
        photoShootTime,
        officeName,
        packageName,
        contractId,
        memo,
    } = usePhotoDetail({
        location_state_data
    })

    useEffect(() => {
        isAbleAccesser(["C"]);
    }, [])

    return (
        <div className={CSS.l_photo_detail_main}>
            <div className={CSS.l_left_wrap}>
                <div className={`g_card ${CSS.l_left_wrap__top_wrap}`}>
                    <Media
                        origin_src={seletedData?.img_url}
                        src={seletedData?.middle_url}
                        object_fit={"contain"}
                    />
                    <div className={CSS.l_image_name_container}>
                        {seletedData?.file_name}
                    </div>
                </div>
                <div className={CSS.l_left_wrap__bottom_wrap}>
                    {
                        imageList.length > 0
                        ?   <>
                                <div className={`g_display_none_object flex mobile ${CSS.l_photo_list_swipe_line_tooltip_container}`}>
                                    <Tooltip
                                        isUseIcon={true}
                                        tooltip_node={
                                            <Text>
                                                {"* 키보드 화살표키(← →) 혹은 < > 버튼으로 다른 사진을 선택할 수 있습니다."}
                                            </Text>
                                        }
                                        max_width={"auto"}
                                    >
                                        조작법
                                    </Tooltip>
                                </div>
                                <PhotoListSwipeLine 
                                    children_list={
                                        imageList.map((item, index) => {
                                            return (
                                                <ImageBlock
                                                    origin_src={item.img_url}
                                                    file_type={item.file_type}
                                                    file_name={item.file_name}
                                                    key={index}
                                                    src={item.small_url} 
                                                    onClick={() => {
                                                        setSelectedIndex(index);
                                                    }}
                                                    is_selectd={item.photo_id === seletedData?.photo_id}
                                                />
                                            )
                                        })
                                    }
                                    selectedIndex={selectedIndex}
                                    setSelectedIndex={setSelectedIndex}
                                    arrow_size={"middel"}
                                />
                            </>
                        :   <WideCard>
                                사진 정보가 없습니다.
                            </WideCard>
                    }
                </div>
            </div>
            <div className={CSS.l_right_wrap}>
                <div className={`g_card ${CSS.l_info_container}`}>
                    {
                        !!photoShootName
                        &&  <TextFlexWrap 
                                title={photo_title}
                                text_list={[photoShootName]}
                                flex_direction={"column"}
                                justify_content={"flex_start"}
                            />
                    }

                    {
                        !!scheduleDateString
                        &&  <TextFlexWrap 
                                title={"촬영일"}
                                text_list={[scheduleDateString]}
                                flex_direction={"column"}
                                justify_content={"flex_start"}
                            />
                    }

                    {
                        (!!photoShootTime.start_time || !!photoShootTime.end_time)
                        &&  <TextFlexWrap 
                                title={"촬영시간"}
                                text_list={[photoShootTime.start_time, "-", photoShootTime.end_time]}
                                flex_direction={"column"}
                                justify_content={"flex_start"}
                            />
                    }

                    {
                        !!officeName
                        &&  <TextFlexWrap 
                                title={"촬영지점"}
                                text_list={[officeName]}
                                flex_direction={"column"}
                                justify_content={"flex_start"}
                            />
                    }

                    {
                        !!packageName
                        &&  <TextFlexWrap 
                                title={"패키지명"}
                                text_list={[packageName]}
                                flex_direction={"column"}
                                justify_content={"flex_start"}
                            />
                    }

                    {
                        !!contractId
                        &&  <TextFlexWrap 
                                title={"계약번호"}
                                text_list={[contractId]}
                                flex_direction={"column"}
                                justify_content={"flex_start"}
                            />
                    }

                    {
                        fileLength > 0
                        &&  <TextFlexWrap 
                                title={"파일 수"}
                                text_list={[`${fileLength}`]}
                                flex_direction={"column"}
                                justify_content={"flex_start"}
                            />
                    }

                    {
                        !!uploadDateString
                        &&  <TextFlexWrap 
                                title={"업로드 일자"}
                                text_list={[uploadDateString]}
                                flex_direction={"column"}
                                justify_content={"flex_start"}
                            />
                    }

                    {
                        !!downloadDateString
                        &&  <TextFlexWrap 
                                title={"다운로드 일자"}
                                text_list={[downloadDateString]}
                                flex_direction={"column"}
                                justify_content={"flex_start"}
                            />
                    }
                    
                    {
                        !!memo
                        &&  <TextFlexWrap 
                                title={"메모"}
                                text_list={[memo]}
                                flex_direction={"column"}
                                justify_content={"flex_start"}
                            />
                    }
                </div>

                {
                    (isEventApplication && !!joined_event)
                    &&  <EventApplication 
                            schedule_id={scheduleId}
                            upload_date={upload_date}
                            joined_event={joined_event}
                            photo_upload_id={photo_upload_id}
                        />
                }
                
                <CustomerConfirm
                    photo_upload_id={photo_upload_id}
                    photo_type={photo_type}
                    customer_confirmed={customer_confirmed}
                    manager_confirmed={manager_confirmed}
                    confirm_reason={confirm_reason}
                    request_num={request_num}
                    contract_product_id={contract_product_id}
                />

                {
                    isPrintDownloadBtn
                    &&  <DownloadBtnContainer
                            download_type={download_type_code}
                            s3_url={s3_url}
                            file_name={`${scheduleDateString}-${getMyCookieData.name()}-${photoShootName}`}
                            upload_date={upload_date}
                            is_print_badge={isPrintDownloadBtnBadge}
                            main_type={parseInt(location_state_data.main_type)}
                            photo_upload_id={photo_upload_id}
                        />
                }
            </div>

            {/* 첫 로딩때 출력되는 모달 */}
            <LoopAnimationModal
                isModalOpen={photoDetailDataIsLoading}
            />
        </div>
    )
}

export default PhotoDetail