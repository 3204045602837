import { createContext, useContext, useEffect, useRef, useState } from "react";
import { arraysAreEqual, customToast, detectDuplicates } from "../../../Common/ts/commonTools";
import UploadedImageContainerModal from "./UploadedImageContainerModal";
import CSS from "../static/css/uploadInputBtnContainer.module.css";
import { albumDataInterface, choicesAvailableNumInterface, previewUrlInterface, productTypeType, scheduleProductInterface } from "../static/interface/photoSelect.interface";
import { photoSelectContext } from "../PhotoSelect";
import TextFlexWrap from "../../../Component/Elements/TextFlexWrap/TextFlexWrap";
import Button from "../../../Component/Elements/Button/Button";
import { addressDataInterface } from "../../../Component/Elements/Postcode/interface/useDaumPostcode.interface";
import { Address } from "react-daum-postcode";
import DaumPostcodeHook from "../../../Component/Elements/Postcode/hooks/useDaumPostcode.hook";
import { getMinMaxPhoto } from "../static/ts/photoSelectTools";

interface UploadInputBtnContainerInterface{
    albumData: albumDataInterface;
    setAlbumData: React.Dispatch<React.SetStateAction<albumDataInterface>>;
    customAddressData: addressDataInterface;
    setCustomAddressData: React.Dispatch<React.SetStateAction<addressDataInterface>>;
    setRowAddressData: React.Dispatch<React.SetStateAction<Address | null>>;
    detailAddress: string;
    setDetailAddress: React.Dispatch<React.SetStateAction<string>>;
    request: string; 
    setRequest: React.Dispatch<React.SetStateAction<string>>;
}

const UploadInputBtnContainerDefaultValue: UploadInputBtnContainerInterface = {
    albumData: {
        baby_name: "",
        baby_birth: "",
        tall: "",
        weight: "",
        baby_birth_time: "",
    },
    setAlbumData: () => {},
    customAddressData: {
        is_set: false,
        zonecode: "",
        mainAddress: "",
        sigunguCode: "",
        bcode: "",
        bname: "",
        buildingName: "",
        buildingCode: "",
        userSelectedType: "R",
    },
    setCustomAddressData: () => {},
    setRowAddressData: () => {},
    detailAddress: "", 
    setDetailAddress: () => {},
    request: "",
    setRequest: () => {},
}

export const uploadInputBtnContainerContext = createContext<UploadInputBtnContainerInterface>(UploadInputBtnContainerDefaultValue);

const UploadInputBtnContainer = (
    {
        selectedProduct,
        uploadInputRef,
        isUploadedImgModalOpen,
        setIsUploadedImgModalOpen,
    }:{
        selectedProduct: scheduleProductInterface;
        uploadInputRef: React.RefObject<HTMLInputElement>;
        isUploadedImgModalOpen: boolean;
        setIsUploadedImgModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    }
) => {

    const [isRequestConfigModalOpen, setIsRequestConfigModalOpen] = useState<boolean>(false);

    // 중복된 이미지 주소들 모음
    // const [duplicatePreviewUrls, setDuplicatePreviewUrls] = useState<previewUrlInterface[]>([]);
    const [choicesAvailableNum, setChoicesAvailableNum] = useState<choicesAvailableNumInterface>({
        min: 0,
        max: 0,
    });
    const [isReRequest, setIsReRequest] = useState<boolean>(false);
    const [functionNode, setFunctionNode] = useState<React.ReactNode | null>(null);

    const [request, setRequest] = useState<string>("");
    const [albumData, setAlbumData] = useState<albumDataInterface>({
        baby_name: "",
        baby_birth: "",
        tall: "",
        weight: "",
        baby_birth_time: "",
    })

    const { customAddressData, setCustomAddressData, setRowAddressData, detailAddress, setDetailAddress } = DaumPostcodeHook(null);

    const selectedFileListRef = useRef<File[]>([]);
    const [previewUrls, setPreviewUrls] = useState<previewUrlInterface[]>([]);

    const photo_select_context = useContext(photoSelectContext);

    const addFile = (files:  File[]) => {
        photo_select_context.setIsUploading(true);

        let total_files = [...selectedFileListRef.current, ...files];
    
        const is_allowd_extension: boolean = total_files.some(file => {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            return fileExtension !== "jpg" || !file.type.startsWith("image/");
        })

        if(is_allowd_extension){
            customToast.info("jpg파일만 업로드가 가능합니다.\n형식에 맞지 않는 파일은 제외됩니다.");

            total_files = total_files.filter(file => {
                const fileExtension = file.name.split('.').pop()?.toLowerCase();
                return fileExtension === "jpg" && file.type.startsWith("image/");
            })
        }

        detectDuplicates(total_files)
        .then((result) => {

            // 중복된 이미지 주소들 처리
            // const _duplicateurls = result.duplicateFiles.map((file) => {
            //     const result: previewUrlInterface = {
            //         file_name: file.name,
            //         file_url: URL.createObjectURL(file),
            //     }
            //     return result;
            // });
            // setDuplicatePreviewUrls(_duplicateurls);

            let final_file_list: File[] = [];
            if(result.duplicateFileNames.length > 0 || result.duplicateFiles.length > 0){
                customToast.info("중복되거나 이름이 같은 사진이 있습니다.\n해당 사진들은 제외됩니다.");
                final_file_list = result.uniqueFiles;
            }else {
                final_file_list = total_files;
            }

            if (!arraysAreEqual<File>(final_file_list, selectedFileListRef.current)) {
                selectedFileListRef.current = final_file_list;
                const urls = final_file_list.map((file) => {
                    const result: previewUrlInterface = {
                        file_name: file.name,
                        file_url: URL.createObjectURL(file),
                    }

                    return result;
                });
                setPreviewUrls(urls);
            }
        })
        .catch((error) => {
            console.error(error);
            customToast.error("파일 검증 과정에서 문제가 발생했습니다.");
        })
        .finally(() => {
            photo_select_context.setIsUploading(false);

            // input value를 비워줘서 동일한 파일을 다시 선택할 때도 onChange 이벤트가 발생하도록 함
            if (uploadInputRef.current) {
                uploadInputRef.current.value = "";
            }
        })

    }

    // drag and drop동작
    const onDropHandler = (event: React.DragEvent<HTMLDivElement>) => {
        const files = Array.from(event.dataTransfer.files); // 드롭된 파일들
        addFile(files);
    }

    // input에 파일이 업로드됬을 때, state에 해당 내용 업로드
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);
        addFile(files);
    };

    // 버튼을 클릭했을 때 input의 클릭 이벤트 호출
    const handleClick = () => {
        // if(photo_select_context.selectedProductOptionDataList.length > 0 && productQty !== selectedOptionList.length){
        //     alert("옵션을 모두 선택해주세요.");
        //     return ;
        // }

        setIsReRequest(false);
        setIsUploadedImgModalOpen(true);
        // uploadInputRef.current?.click();
    };

    const handleReUpload = () => {
        if(!window.confirm("재 선택을 진행하실경우 기존에 선택하신 내용이 초기화됩니다.\n진행하시겠습니까?")){
            return ;
        }

        setIsReRequest(true);
        setIsUploadedImgModalOpen(true);
        // uploadInputRef.current?.click();
    }

    const handleRequestedDataOpenBtn = () => {
        setIsRequestConfigModalOpen(true);
    }

    const isAlbum = (product_type: productTypeType) => {
        return product_type === "2";
    }

    useEffect(() => {
        if(selectedProduct){
            if(selectedProduct.detail && selectedProduct.detail.length > 0){
                setChoicesAvailableNum(getMinMaxPhoto(selectedProduct.detail[0]));
            }

            // 선택된 제품에서 설정된 제품정보가 있는지 확인
            setAlbumData({
                baby_name: selectedProduct.name || "",
                baby_birth: selectedProduct.birth || "",
                tall: selectedProduct.height || "",
                weight: selectedProduct.weight || "",
                baby_birth_time: selectedProduct.birth_time || "",
            })

            setRequest(selectedProduct.common_memo || "");

            setRowAddressData(null);

            let address_data_is_set:boolean = false;
            if(selectedProduct.zip_code && selectedProduct.addr && selectedProduct.building_no){
                address_data_is_set = true;
            }

            setCustomAddressData((item) => {
                return({
                    ...item,
                    zonecode: selectedProduct.zip_code || "",
                    mainAddress: selectedProduct.addr || "",
                    buildingCode: selectedProduct.building_no || "",
                    is_set: address_data_is_set,
                })
            })
            setDetailAddress(selectedProduct.addr_detail || "");
        }
    }, [selectedProduct])

    useEffect(() => {
        let node_state_code = 0;

        if(photo_select_context.isOptionError){
            node_state_code = -1;
        }else if(photo_select_context.isOptionLoading){
            node_state_code = 1;
        }else if(selectedProduct.photo_select_pre_yn === 1){
            if(selectedProduct.final_select === "N"){

                if(choicesAvailableNum.min > 0 && choicesAvailableNum.max > 0){
                    node_state_code = 3;
                }else{
                    node_state_code = -3;
                }

            }else{
                node_state_code = 4;
            }
        }else{
            node_state_code = -2;
        }

        switch(node_state_code){
            case -1:
                // 옵션 정보 호출 중 오류발생
                setFunctionNode(
                    <TextFlexWrap
                        text_list={["제품의 선택 옵션데이터를 가져오는 과정에서 문제가 발생했습니다."]}
                        flex_direction={"column"}
                        justify_content={"center"}
                    />
                );
                break;
            case 1:
                // 옵션정보 호출 중...
                setFunctionNode(  
                    <div className={`g_display_none_object mobile flex ${CSS.l_btn_wrap}`}>
                        <Button
                            size={"size_full"} 
                            disabled={true}
                        >
                            {`잠시만 기다려주세요.`}
                        </Button>
                    </div>                          
                );
                break;
            case -2:
                // 촬영들이 종료되지 않음
                setFunctionNode(
                    <div className={`g_display_none_object mobile flex ${CSS.l_btn_wrap}`}>
                        <Button
                            size={"size_full"}
                            onClick={handleClick} 
                            disabled={true}
                        >
                            {"촬영이 모두 완료되지 않았습니다."}
                        </Button>
                    </div>
                );
                break;
            case 3:
                // 사진 선택 진행 가능
                setFunctionNode(
                    <div className={`g_display_none_object mobile flex ${CSS.l_btn_wrap}`}>
                        <input
                            type="file"
                            multiple
                            onChange={handleFileChange}
                            ref={uploadInputRef}
                            style={{
                                display: "none"
                            }}
                            // accept="image/*"
                            accept=".jpg"
                        />
                        <Button
                            size={"size_full"}
                            onClick={handleClick} 
                        >
                            {`제품 제작 신청(최대 ${choicesAvailableNum.max}장)`}
                        </Button>
                    </div>
                );
                break;
            case -3:
                // 사진의 수가 설정되지 않아서 진행 불가능
                setFunctionNode(
                    <TextFlexWrap
                        text_list={["선택해야할 사진의 수가 설정되지 않았습니다. 지점에 문의해주세요."]}
                        flex_direction={"column"}
                        justify_content={"center"}
                    />
                );
                break;
            case 4:
                // 선택이 완료됨
                setFunctionNode(
                    <div className={`g_display_none_object mobile flex ${CSS.l_btn_wrap}`}>
                        {
                            selectedProduct.staff_download_at.length === 0
                            ?   <>
                                    <input
                                        type="file"
                                        multiple
                                        onChange={handleFileChange}
                                        ref={uploadInputRef}
                                        style={{
                                            display: "none"
                                        }}
                                        // accept="image/*"
                                        accept=".jpg"
                                    />
                                    <Button 
                                        size={"size1"}
                                        purpose={"cancel"}
                                        onClick={handleReUpload}
                                    >
                                        {`재신청(최대 ${choicesAvailableNum.max}장)`}
                                    </Button>
                                </>
                            :   selectedProduct.goout === "N"
                                &&  <TextFlexWrap
                                        text_list={["선택이 완료되었습니다."]}
                                        flex_direction={"column"}
                                        justify_content={"center"}
                                    />
                        }

                        <Button 
                            size={"size1"}
                            onClick={handleRequestedDataOpenBtn}
                        >
                            신청 내역 확인
                        </Button>
                    </div>
                );
                break;
            default:
                setFunctionNode(null);
        }
    }, [selectedProduct, photo_select_context.isOptionError, photo_select_context.isOptionLoading, choicesAvailableNum])

    return (
        <uploadInputBtnContainerContext.Provider 
            value={{
                albumData,
                setAlbumData,
                customAddressData,
                setCustomAddressData,
                setRowAddressData,
                detailAddress,
                setDetailAddress,
                request,
                setRequest,
            }}
        >
            {/* 각 조건에따라 최종적을 설정된 선택된 제품 컴포넌트 */}
            {functionNode}

            {/* 이미지를 업로드할때 출력되는 모달 */}
            <UploadedImageContainerModal
                selectedProduct={selectedProduct}
                quantity_of_photos_to_choose={choicesAvailableNum}
                previewUrls={previewUrls}
                setPreviewUrls={setPreviewUrls}
                uploadInputRef={uploadInputRef}
                isReRequest={isReRequest}
                is_album={isAlbum(selectedProduct.product_type)}

                isModalOpen={isUploadedImgModalOpen}
                setIsModalOpen={setIsUploadedImgModalOpen}
                
                selectedFileListRef={selectedFileListRef}
                onDropHandler={onDropHandler}
            /> 

            {/* 이미 업로드한 사진들을 출력하는 모달 */}
            <UploadedImageContainerModal
                selectedProduct={selectedProduct}
                quantity_of_photos_to_choose={choicesAvailableNum}
                previewUrls={previewUrls}
                setPreviewUrls={setPreviewUrls}
                uploadInputRef={uploadInputRef}
                isReRequest={isReRequest}
                is_album={isAlbum(selectedProduct.product_type)}
                
                isModalOpen={isRequestConfigModalOpen}
                setIsModalOpen={setIsRequestConfigModalOpen}

                readonly={true}
            />
        </uploadInputBtnContainerContext.Provider>
    )
}

export default UploadInputBtnContainer;