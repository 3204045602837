import { ChangeEvent, useContext, useEffect, useState } from "react";
import PasswordInputWrap from "./components/PasswordInputWrap";
import { myInfoContext } from "./MyInfo";
import { callAxios, customToast, dev_console, printStateMsg, validatePassword } from "../../Common/ts/commonTools";
import PasswordConfimMsgCard from "./components/PasswordConfimMsgCard";
import { logout } from "../../Common/ts/appTools";
import CSS from "./static/css/changePassword.module.css";
import Button from "../../Component/Elements/Button/Button";

function ChangePassword() {

    const [isOriginPasswordError, setIsOriginPasswordError] = useState<boolean>(false);

    const [existingPassword, setExistingPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordConfrim, setNewPasswordConfrim] = useState<string>("");

    const [passwordConfimMsg, setPasswordConfimMsg] = useState<string[]>([]);
    const [isSamePassword, setIsSamePassword] = useState<boolean>(false);

    const [isCanSave, setIsCanSave] = useState<boolean>(false);

    const myInfo_context = useContext(myInfoContext);
    
    const hanelExistingPasswordOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setExistingPassword(event.target.value);
        setIsOriginPasswordError(false);
    }

    const hanelNewPasswordOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
        const result: string[] = validatePassword(event.target.value);
        setPasswordConfimMsg(result);

        if(newPasswordConfrim.length > 0){
            setIsSamePassword(newPasswordConfrim === event.target.value);
        }else{
            setIsSamePassword(false)
        }
    }

    const hanelNewPasswordConfrimOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewPasswordConfrim(event.target.value);
        if(event.target.value.length > 0){
            setIsSamePassword(newPassword === event.target.value);
        }else{
            setIsSamePassword(false);
        }
    }

    const onSubmit = () => {
        if(existingPassword === newPassword){
            customToast.error("이전과 같은 비밀번호로는 변경할 수 없습니다.");
            return ;
        }
        
        if(!window.confirm("비밀번호를 변경하시겠습니까?")){
            return ;
        }

        const config = {
            old_password: existingPassword,
            new_password: newPassword,
            confirm_password: newPasswordConfrim
        }

        const password_change_url = 'api/v1/accounts/password_change/';

        callAxios.api({
            method: 'post',
            url: password_change_url,
            data: config,
            dataType: 'json'
        })
        .then((response) => {
            dev_console.log(password_change_url);
            dev_console.log(response);

            if(response.status === 200){
                alert("비밀번호 변경이 완료되었습니다. 다시 로그인 해주세요.");
                logout();
            }else{
                printStateMsg(response);

                if(response.status === 400){
                    customToast.error("비밀번호 변경에 실패했습니다.\n기존 비밀번호를 확인해주세요.");
                    setIsOriginPasswordError(true);
                }else{
                    customToast.error("문제가 발생했습니다. 관리자에게 문의해주세요.");
                }
            }
        })
        .catch((error) => {
            dev_console.error(error);

            if(error.response.status === 400){
                customToast.error("비밀번호 변경에 실패했습니다.\n기존 비밀번호를 확인해주세요.");
                setIsOriginPasswordError(true);
            }else if(error.response.status === 401){
                logout();
            }else{
                customToast.error("문제가 발생했습니다. 관리자에게 문의해주세요.");
            }
        })
    }

    const onCancel = () => {
        if(window.confirm("저장되지 않은 정보들은 초기화 됩니다.")){
            setExistingPassword("");
            setNewPassword("");
            setNewPasswordConfrim("");
            myInfo_context.setIsPasswordChageMode(false);
        }
    }

    // 비밀번호 변경을 진행해도 되는지 검증
    const conditionVerification = () => {
        if(existingPassword.length === 0 || newPassword.length === 0 || newPasswordConfrim.length === 0){
            setIsCanSave(false);
            return ;
        }

        if(passwordConfimMsg.length > 0){
            setIsCanSave(false);
            return ;
        }

        if(!isSamePassword){
            setIsCanSave(false);
            return ;
        }

        setIsCanSave(true);
    }

    useEffect(() => {
        conditionVerification();
    }, [existingPassword, newPassword, newPasswordConfrim, passwordConfimMsg, isSamePassword])

    return (
        <div className={`g_card ${CSS.l_change_password_main}`}>
            <div className="g_title">
                비밀번호 변경
            </div>
            <div className={`${CSS.l_password_main_container}`}>
                <div className={`${CSS.l_password_info_wrap}`}>
                    <PasswordInputWrap
                        value={existingPassword} 
                        onChange={hanelExistingPasswordOnChange}
                        label="기존 비밀번호" 
                        name={"old_password"}
                    >
                        {
                            isOriginPasswordError
                            &&  <PasswordConfimMsgCard 
                                    symbol="!"
                                    msg_list={["기존 비밀번호를 확인해주세요."]}
                                />
                        }
                    </PasswordInputWrap>
                    <PasswordInputWrap 
                        value={newPassword} 
                        onChange={hanelNewPasswordOnChange}
                        maxLength={12}
                        label="새 비밀번호" 
                        name={"new_password"}
                    >
                        {
                            newPassword.length > 0 && passwordConfimMsg.length > 0
                            &&  <PasswordConfimMsgCard 
                                    symbol="∙"
                                    msg_list={passwordConfimMsg}
                                />
                        }
                    </PasswordInputWrap>
                    <PasswordInputWrap 
                        value={newPasswordConfrim} 
                        onChange={hanelNewPasswordConfrimOnChange}
                        maxLength={12}
                        label="새 비밀번호(재입력)"
                        name="confirm_password"
                    >
                        {
                            newPasswordConfrim.length > 0 && !isSamePassword
                            &&  <PasswordConfimMsgCard 
                                    symbol="∙"
                                    msg_list={["새로운 비밀번호가 일치하지 않습니다"]}
                                />
                        }
                    </PasswordInputWrap>
                </div>
            </div>

            <div className={CSS.l_button_wrap}>
                <Button
                    size={"size1"}
                    purpose={"cancel"}
                    onClick={onCancel}
                    type={"button"}
                >
                    취소
                </Button>
                {
                    isCanSave
                    &&  <Button 
                            size={"size1"}
                            purpose={"save"}
                            type={"button"}
                            onClick={onSubmit}
                        >
                            저장
                        </Button>
                }
            </div>
        </div>
    );
}
export default ChangePassword;