import MediaCard from "../../../Component/Elements/MediaCard/MediaCard";
import CSS from "../static/css/photoListBlock.module.css";
import PhotoCardInfoTextWrap from "./PhotoCardInfoTextWrap";
import { joinedEventType } from "../static/interface/PhotoList.interface";
import EventApplication from "./EventApplication/EventApplication";
import Text from "../../../Component/Elements/Text/Text";
import { flagInterface } from "../static/interface/confirmEvent.Interface";
import { useEffect, useState } from "react";

const PhotoListBlock = (
    {
        img_src,
        title,
        date,
        onClick,
        is_print_badge,
        schedule_id,
        upload_date,
        joined_event,
        is_print_join_event_btn,
        is_print_confirm,
        photo_upload_id,
    }:{
        img_src: string;
        title: string;
        date: string;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        is_print_badge: boolean;
        schedule_id: number;
        upload_date: string;
        joined_event: joinedEventType;
        is_print_join_event_btn: boolean;
        is_print_confirm: flagInterface | null;
        photo_upload_id: number;
    }
) => {

    const [confirmEventNode, setConfirmEventNode] = useState<React.ReactNode | null>(null);

    useEffect(() => {
        let state: number = 0;

        if(is_print_confirm){
            if(is_print_confirm.flag){
                state = 1;
            }else{
                if(is_print_confirm.order === "C"){
                    state = 2;
                }else if(is_print_confirm.order === "M"){
                    state = 3;
                }else{
                    state = 0;
                } 
            }
        }else{
            state = 0;
        }

        switch(state){
            case 1:
                setConfirmEventNode(
                    <Text
                        class_name_list={[CSS.l_confirm_msg]}
                        size={"size1"}
                        color={"red"}
                    >
                        사진을 확인해주세요!
                    </Text>
                )
                break;
            case 2:
                setConfirmEventNode(
                    <Text
                        class_name_list={[CSS.l_confirm_msg]}
                        size={"size1"}
                    >
                        재편집이 요청되었습니다.
                    </Text>
                )
                break;
            case 3:
                setConfirmEventNode(
                    <Text
                        class_name_list={[CSS.l_confirm_msg]}
                        size={"size1"}
                    >
                        담당자에 의하여 재편집이 요청되었습니다.
                    </Text>
                )
                break;
            default:
                setConfirmEventNode(null)
                ;
        }

    }, [is_print_confirm])

    return (
        <div className={`g_grid_item g_click_event_item ${CSS.l_photo_card_main}`}>
            <MediaCard
                onClick={onClick}
                src={img_src}
                size={"middle"}
                is_hover_action_activate={true}
                children_container_class_name_list={[CSS.l_media_card_container]}
                is_print_badge={is_print_badge}
            >
                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <PhotoCardInfoTextWrap 
                        title={title}
                        date={date}
                    >
                        {
                            is_print_join_event_btn
                            &&  <EventApplication
                                    schedule_id={`${schedule_id || ""}`}
                                    upload_date={upload_date}
                                    joined_event={joined_event}
                                    size={"auto"}
                                    photo_upload_id={photo_upload_id}
                                />
                        }

                        {confirmEventNode}
                    </PhotoCardInfoTextWrap>

                </div>
            </MediaCard>
        </div>
    )
}

export default PhotoListBlock;