import { useEffect } from "react";
import { photoDetailDataInterface } from "../static/interface/PhotoDetail.interface";
import { callAxios, customToast, dev_console, whoAmI } from "../../../Common/ts/commonTools";
import { useQuery } from "@tanstack/react-query";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";

const usePhotoDetailDataREST = (
    {
        photo_upload_id,
        main_type,
    }:{
        photo_upload_id: number,
        main_type: number;
    }
) => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            if(!!photo_upload_id && !!main_type){
                    result = true;
            }
        }

        return result;
    }

    const {
        data: photoDetailData = null,
        error: photoDetailDataError,
        isLoading: photoDetailDataIsLoading,
        refetch: photoDetailDataRefetch,
    } = useQuery({
        queryKey: ["photoDetailData", photo_upload_id, main_type],
        queryFn: callPhotoDetailData,
        select: (response) => {
            if(response){
                if(response.status === 200){
                    const response_data:photoDetailDataInterface[] = response.data.datas;
                    if(response_data.length > 0){
                        return response_data[0];
                    }else{
                        return null;
                    }
                }else{
                    return null;
                }
            }else{
                return null;
            }
        },
        enabled: isAbleToRequest(),
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
        refetchOnMount: false,  // 다시 마운트 되더라도 새로운 요청을 보내지 않게 처리
    })

    async function callPhotoDetailData(){
        const photo_detail_url = `api/v3/customers/photo_detail?photo_upload_id=${photo_upload_id}&main_type=${main_type}`;
        const response = await callAxios.api({
            url: photo_detail_url,
            method: "get",
        })

        dev_console.log(photo_detail_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        if(!!photoDetailDataError){
            dev_console.error(photoDetailDataError);
            customToast.error("촬영 사진의 데이터 요청 중 문제가 발생했습니다.");
        }
    }, [photoDetailDataError])

    return({
        photoDetailData,
        photoDetailDataIsLoading,
        photoDetailDataRefetch,
    })
}

export default usePhotoDetailDataREST;