import { useEffect, useState } from "react";
import { contractListDataInterace } from "../interface/ContractDocs.interface";
import useContractListREST from "./useContractListREST.hook";

const useContractDocs = () => {
    const [selectedContractDocs, setSelectedContractDocs] = useState<contractListDataInterace | null>(null);

    const {
        contractList,
        contractListError,
        contractListIsLoading,
    } = useContractListREST();

    useEffect(() => {
        if(contractList && contractList.length > 0){
            setSelectedContractDocs(contractList[0]);
        }else{
            setSelectedContractDocs(null);
        }
    }, [contractList])

    return({
        contractList,
        selectedContractDocs, 
        setSelectedContractDocs, 
    })
}

export default useContractDocs;