import { regionInterface } from "../interface/Common.interface";

export const VIEW_SIEZ = {
    sd: {
        px: {
            width: 854,
            height: 480,
            unit: "px",
        }

    },

    hd: {
        px: {
            width: 1280,
            height: 720,
            unit: "px",
        }
    },

    fhd: {    
        px: {
            width: 1920,
            height: 1080,
            unit: "px",
        }
    },
    
    uhd: {
        px: {
            width: 3840,
            height: 2160,
            unit: "px",
        }
    }
    
}


// // 아직 오피스 정보가 없는 지역들
// const region_list_emplty_office = ["강원도", "경상도", "제주도"];

// 오피스 정보를 출력 가능한 지역
export const REGION_LIST: regionInterface[] = [
    {
        name: '서울',
        value: '서울',
        cities: [
            "강남구", "강동구", "강북구", "강서구", "관악구", "광진구", "구로구", "금천구", "노원구", "도봉구", "동대문구", "동작구", 
            "마포구", "서대문구", "서초구", "성동구", "성북구", "송파구", "양천구", "영등포구", "용산구", "은평구", "종로구", "중구", "중랑구"
        ]
    },
    {
        name: '경기도', 
        value: '경기',
        cities: [
            "수원시", "성남시", "용인시", "고양시", "안양시", "안산시", "부천시", "의정부시", "평택시", "시흥시", "김포시", "파주시", "광명시", 
            "화성시", "군포시", "하남시", "이천시", "오산시", "양주시", "구리시", "남양주시", "의왕시", "여주시", "동두천시", "광주시", "과천시", 
            "안성시", "포천시", "양평군", "가평군", "연천군"
        ]
    },
    {
        name: '충청도', 
        value: '충청',
        cities: [
            "천안시", "공주시", "보령시", "아산시", "서산시", "논산시", "계룡시", "당진시", "금산군", "부여군", "서천군", "청양군", "홍성군", 
            "예산군", "태안군", "청주시", "충주시", "제천시", "보은군", "옥천군", "영동군", "증평군", "진천군", "괴산군", "음성군", "단양군"
        ]
    },
    {
        name: '강원도', 
        value: '강원',
        cities: [
            "춘천시", "원주시", "강릉시", "동해시", "태백시", "속초시", "삼척시", "홍천군", "횡성군", "영월군", "평창군", "정선군", "철원군", 
            "화천군", "양구군", "인제군", "고성군", "양양군"
        ]
    },
    {
        name: '전라도', 
        value: '전라',
        cities: [
            "목포시", "여수시", "순천시", "나주시", "광양시", "담양군", "곡성군", "구례군", "고흥군", "보성군", "화순군", "장흥군", "강진군", 
            "해남군", "영암군", "무안군", "함평군", "영광군", "장성군", "완도군", "진도군", "신안군", "전주시", "군산시", "익산시", "정읍시", 
            "남원시", "김제시", "완주군", "진안군", "무주군", "장수군", "임실군", "순창군", "고창군", "부안군"
        ]
    },
    {
        name: '경상도', 
        value: '경상',
        cities: [
            "포항시", "경주시", "김천시", "안동시", "구미시", "영주시", "영천시", "상주시", "문경시", "경산시", "의성군", "청송군", "영양군", 
            "영덕군", "청도군", "고령군", "성주군", "칠곡군", "예천군", "봉화군", "울진군", "울릉군", "창원시", "진주시", "통영시", "사천시", 
            "김해시", "밀양시", "거제시", "양산시", "의령군", "함안군", "창녕군", "고성군", "남해군", "하동군", "산청군", "함양군", "거창군", "합천군"
        ]
    },
    {
        name: '제주도',
        value: '제주',
        cities: [
            "제주시", "서귀포시"
        ]
    },
];

// 청크사이즈
export const CHUNK_SIZE = 1024 * 1024; // 1MB

// 편집 요청 예시
export const EXAMPLE_TEXT_LIST: string[] = ["-요청 예시-", "(ex. 사진들의 전체적인 톤을 밝게 해주세요.)", "(ex. 아이의 입에 침 자국을 지워주세요.)", `\n`, "-주의사항-", `"OO번째 사진을 편집해주세요." 같이 사진의 순서로 지목하시면`, "잘못된 사진에 편집이 진행될 가능성이 있습니다.", "사진파일 이름으로 지목해주세요.", "(ex. 아이사진.jpg의 톤을 보정해주세요.)"];

// tanstack query 캐시유지 관련 값
export const STALE_TIME = 1000 * 60 * 5;
export const GC_TIME = 1000 * 60 * 10;